import * as React from "react";
import AWS from 'aws-sdk';
import config from 'config';

import { ReactComponent as IconTrash } from "assets/svg/trash.svg";
import { ReactComponent as IconClose } from "assets/svg/close.svg";
import { ReactComponent as IconAlertSuccess } from "assets/svg/alert-success.svg";

import api from "api";
import {
  UserObject,
  UserFormSchema,
  UserFormObject,
  allowedRolesForUser,
} from "api/endpoints/users";
import { useEffect, useState } from "react";
import useRequest from "api/use-request";
import { Form, FormNotification } from "components/form/form";
import { FormFieldVariant } from "components/form/common";
import InputField, { InputFieldType } from "components/form/input";
import FileDropzoneField from "components/form/file-dropzone-field";
import { SubmitButton } from "components/form/submit-button";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";
import { debug } from "utils/debug";
import DeleteConfirmationAlert, {
  DeleteConfirmationAlertType,
} from "components/form/delete-confirmation";
import { Multiselect } from "components/controls/multiselect-control";
import { Field } from "formik";
import classNames from "classnames";
import { store } from "store";
import { SendResetLinkRequest } from "api/endpoints/password";
import { EditModalSkeleton } from "./skeletons/edit-modal-skeleton";
import formatDate from "utils/date";
import { hash } from "utils/string";

export interface IUserDetailsProps {
  isNew: boolean;
  nextLocation: string;
  onUpdate: () => void;
  isProfile: boolean;
}

function newUserObj(): UserFormObject {
  return _.clone({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    role: "user",
    new_password: "",
    new_password_confirm: "",
  });
}

export function UserDetails({ isNew, nextLocation, onUpdate, isProfile }: IUserDetailsProps) {
  let [initialValue, setInitialValue] = useState<UserFormObject>();
  let [isUploading, setIsUploading] = useState(false);
  let [isDeleting, setIsDeleting] = useState(false);
  let [uploadedFilePath, setUploadedFilePath] = useState("");
  let [uploadingFileName, setUploadingFileName] = useState("");
  let [editedUser, setEditedUser] = useState({} as UserObject);
  let currentUser = useRequest(api.users.getCurrentUser());
  let [passwordResetLinkSent, setPasswordResetLinkSent] = useState(false);
  let [filename, setFilename] = useState<undefined | string>(undefined);
  let [profileImageUrl, setProfileImageUrl] = useState<undefined | string>(undefined);
  let [selectedRole, setSelectedRole] = useState(editedUser.role);

  let validationSchema = UserFormSchema.omit({
    id: true,
    profile_image: true,
    profile_image_url: true,
  }).nonstrict();

  let history = useHistory();
  let { userId } = useParams() as {
    userId: string | undefined;
  };
  if (isProfile && currentUser.data) {
    userId = currentUser.data.id.toString();
  }

  useEffect(() => {
    if (initialValue) {
      setSelectedRole(initialValue.role);

      if (initialValue.profile_image) {
        setProfileImageUrl(config.CDN_URL + initialValue.profile_image);
      }
    }
  }, [initialValue]);

  const submitHandler = async (values: any, formikHelpers: any) => {
    const payload: UserFormObject = {
      id: userId ? userId : "",
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      role: values.role,
      profile_image: filename ? filename : undefined
    };

    let update: Promise<UserObject>;
    if (isNew) {
      update = api.users.newUser(payload).fetch();
    } else {
      update = api.users.updateUser(payload, userId ?? "").fetch();
    }

    return update.then((response) => {
      store.notifications.presentNotification("Profile changes were saved.");
      onUpdate();

      if (isNew) {
        api.users
          .sendWelcomeEmail({
            user_id: response.id,
            return_url: window.location.origin + "/verify-email",
          })
          .fetch();
      }

      if (nextLocation !== "") {
        history.replace(nextLocation);
      } else {
        history.goBack();
      }
    });
  };

  const loadUser = async (userId: string) => {
    let user = await api.users.getUser({ id: userId }).fetch();
    if (user) {
      setEditedUser(user);
      setInitialValue(user);
    }
  };

  const initForm = () => {
    if (!userId) {
      if (isProfile && currentUser.data) {
        userId = currentUser.data.id.toString();
        setEditedUser(currentUser.data);
        setInitialValue(currentUser.data);
      } else {
        setInitialValue(newUserObj());
      }
    } else {
      loadUser(userId);
    }
  };

  useEffect(initForm, []);

  useEffect(() => {
    document.body.classList.add("has-modal-open");
    return () => {
      document.body.classList.remove("has-modal-open");
    };
  });

  function deleteEditedUser() {
    if (userId) {
      let update = api.users.deleteUser({ id: userId }).fetch();
      onUpdate();
      update
        .then((response) => {
          setIsDeleting(false);
          history.push(nextLocation);
        })
        .catch((error) => {
          setIsDeleting(false);
          debug(error);
        });
    }
  }

  function closeModal() {
    history.goBack();
  }

  function sendResetPasswordEmail() {
    const payload: SendResetLinkRequest = {
      email: editedUser.email,
      reset_url: window.location.origin + "/password/reset",
    };
    api.password
      .sendResetLink(payload)
      .fetch()
      .then((response) => {
        setPasswordResetLinkSent(true);
      });
  }

  return (
    <>
      {initialValue ? (
        <div className="c-modal__root">
          <div className="c-modal__wrapper c-modal__wrapper--lg">
            <div className="c-modal c-modal--overflow">
              <div className="c-modal__header">
                <div className="c-block c-block--spacing-t-extra-small">
                  <div className="o-container-fluid">
                    <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                      <div className="o-col">
                        <div
                          onClick={() => {
                            closeModal();
                          }}
                          className="c-link-cta-basic"
                        >
                          <span>Close</span>
                          <IconClose className="o-svg-icon" />
                        </div>
                      </div>
                      <div className="o-col u-flex-grow">
                        <p className="c-modal__headline">{isNew ? "Add" : "Edit"} user</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="c-modal__main">
                <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
                  <div className="o-container-fluid">
                    <Form
                      validationSchema={validationSchema}
                      initialValues={initialValue}
                      onSubmit={submitHandler}
                      enableReinitialize={true}
                    >
                      {({ values, errors, setFieldValue }) => (
                        <div className="o-row">
                          <div className="o-col-3@sm o-col-3@md o-col-3@lg">
                            <h6>Profile Picture</h6>
                            <div>
                              <FileDropzoneField
                                setFieldValue={setFilename}
                                dropHandler={setFilename}
                                defaultImage={profileImageUrl}
                                label=""
                                shouldOpenBrowseDialog={false}
                                uploadDirectory="profile_images"
                              />
                            </div>
                          </div>
                          <div className="o-col-6@sm o-col-6@md o-col-6@lg">
                            <FormNotification />
                            <div className="o-row">
                              <div className="o-col-6@lg o-col-12@xl">
                                <div className="u-mb-spacer-base-large">
                                  <h6>About</h6>
                                  <div className="o-row o-row--small-gutters">
                                    <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`first_name`}
                                        placeholder="First Name"
                                        label="First Name"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                    <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`last_name`}
                                        placeholder="Last Name"
                                        label="Last Name"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                    <div className="o-col-12">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`email`}
                                        placeholder="Email Address"
                                        label="Email Address"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>

                                    {currentUser.data ? (
                                      <div className="o-col-12">
                                        <label
                                          htmlFor={"role"}
                                          className={classNames("c-form-label")}
                                        >
                                          User Role
                                        </label>
                                        <Field
                                          name="role"
                                          id="role"
                                          component={Multiselect}
                                          placeholder="User Role"
                                          options={allowedRolesForUser(
                                            currentUser.data,
                                            editedUser
                                          ).map((role) => {
                                            return { value: role.role, label: role.title };
                                          })}
                                          isMulti={false}
                                          onChange={(selectedValue: string) => {
                                            setSelectedRole(selectedValue);
                                          }}
                                        />
                                        <br />
                                      </div>
                                    ) : (
                                      <div />
                                    )}
                                  </div>
                                </div>
                                {!isNew && (
                                  <div className="u-mb-spacer-base-large">
                                    <h6>Change password</h6>
                                    <div className="o-row o-row--small-gutters">
                                      <div className="o-col-12@md o-col-12@lg o-col-12@xl">
                                        {passwordResetLinkSent ? (
                                          <div className="c-alert c-alert--small@xs c-alert--row@md c-alert--success c-color--invert">
                                            <div className="c-alert__col c-alert__icon">
                                              <IconAlertSuccess className="o-svg-icon" />
                                            </div>
                                            <div className="c-alert__col c-alert__body">
                                              <p>
                                                <strong>Password change requested</strong>
                                              </p>
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            className="c-link-cta"
                                            onClick={(event) => {
                                              event.preventDefault();
                                              sendResetPasswordEmail();
                                            }}
                                          >
                                            <span>Request password change</span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="o-col-3@sm o-col-3@md o-col-3@lg">
                            <div className="c-card c-card--bg-light">
                              <div className="c-card__body">
                                <div className="c-card__header">
                                  <h6>{isNew ? "Create" : "Update"}</h6>
                                  {!isNew && (
                                    <div className="c-card__desc">
                                      {editedUser && editedUser.updated_at ? (
                                        <p>
                                          This user was last updated{" "}
                                          {formatDate(editedUser.updated_at)}.
                                        </p>
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="o-row o-row--fluid c-button-group">
                                  <div className="o-col">
                                    <SubmitButton disabled={isUploading}>
                                      <span>{isNew ? "Create" : "Update"} user</span>
                                    </SubmitButton>
                                  </div>
                                  {!isNew && (
                                    <div className="o-col c-button-group__inline">
                                      <div
                                        className="c-link-cta-basic"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          setIsDeleting(true);
                                        }}
                                      >
                                        <IconTrash className="o-svg-icon" />
                                        <span>Delete account</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <DeleteConfirmationAlert
                                onDelete={deleteEditedUser}
                                onCancel={() => {
                                  setIsDeleting(false);
                                }}
                                resource_label={editedUser.first_name + " " + editedUser.last_name}
                                show={isDeleting}
                                type={DeleteConfirmationAlertType.Card}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EditModalSkeleton />
      )}
    </>
  );
}
