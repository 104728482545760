import * as React from "react";
import useClickAway from "hooks/use-click-away";
import { Link } from "react-router-dom";

// Hooks
import { useContext, useRef, useState } from "react";
import { CertificateObject } from "api/endpoints/certificates";

interface ITableCertificatesCardProps {
  certificate: CertificateObject;
}

const TableCertificatesCard: React.FunctionComponent<ITableCertificatesCardProps> = ({ certificate }) => {
  return (
    <>
      <div className="c-table-row__content">
        <p className="c-table-row__title " style={{ width: "20%" }}>{certificate.hostname}</p>
        <p className="c-table-row__title " style={{ width: "20%" }}>{certificate.is_expired ? "Expired" : certificate.days_to_expiration + " days"}</p>
        <p className="c-table-row__title " style={{ width: "20%" }}>{certificate.issuer}</p>
        <p className="c-table-row__title " style={{ width: "20%" }}>{certificate.signature}</p>
        <p className="c-table-row__title " style={{ width: "20%" }}>{certificate.synced_at}</p>
      </div>
    </>
  );
};

export default TableCertificatesCard;
