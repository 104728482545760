import * as React from 'react';
import { RouteProps } from 'react-router';
import GuardedRoute from 'components/routes/guarded';
import Page403Error from 'pages/access-denied';
import { defineAbilityFor, TAppAbilityCanParams } from 'authorizations';
import useRequest from 'api/use-request';
import api from 'api';
import { useStore } from 'store';
import MainLayout from 'layouts/main';
import { Redirect, useLocation } from 'react-router-dom';
import { LocationState } from 'routes';
import { observer } from 'mobx-react';

export interface AuthRouteProps extends RouteProps {
  location?: RouteLocation
}

interface RouteLocation extends Location {
  state: LocationState,
}

/**
 * Auth route redirects authenticated users to their main app screens
 * @param param0
 * @returns
 */
export const AuthRoute = observer((props: AuthRouteProps) => {
  const store = useStore();
  const user = useRequest(api.users.getCurrentUser(), store.isLoggedIn);
  
  return (
    <GuardedRoute
      {...props}
      guard={(params, children) => {
        if (store.isLoggedIn && store.userRole) {
          let destination = '/404';
          
          window.loader(false);
          if (props.location?.state && props.location.state.from) {
            destination = props.location.state.from.pathname;
          } else if (store.isSuperAdmin) {
            destination = '/users';
          }
          return (
            <Redirect to={destination} />
          );
        } else {
          return children();
        }
      }}
    />
  );
});

export default AuthRoute;
