import HttpClient from "api/http-client";
import * as z from "zod";
import { GetListingRequestSchema, LinksSchema, MetaSchema } from "api/endpoints/common";

const dateRegex =
  /^(?<fullyear>\d{4})-(?<month>0[1-9]|1[0-2])-(?<mday>0[1-9]|[12][0-9]|3[01])T(?<hour>[01][0-9]|2[0-3]):(?<minute>[0-5][0-9]):(?<second>[0-5][0-9]|60)(?<secfrac>\.[0-9]+)?(Z|(\+|-)(?<offset_hour>[01][0-9]|2[0-3]):(?<offset_minute>[0-5][0-9]))$/i;

const WordpressSchema = z.object({
  vulnerabilities: z.array(z.any()).optional(),
  current_wp_version: z.string().optional(),
  date: z.string().optional(),
  send_notification: z.boolean().optional(),
});

const PluginSchema = z.object({
  name: z.string().optional(),
  plugin_current_version: z.string().optional(),
  slug: z.string().optional(),
  date: z.string().optional(),
  send_notification: z.boolean().optional(),
  vulnerabilities: z.array(z.any()).optional(),
});

export const SiteObjectSchema = z.object({
  id: z.string(),
  client_id: z.string().nonempty(),
  name: z.string().nonempty(),
  domain: z.string().nonempty(),
  api_key: z.string().nonempty(),
  user_id: z.string().nonempty(),
  status: z.number(),
  plugins: z.array(z.any()).optional(),
  wp_version: z.any().optional(),
  critical: z.object({
    plugins: z.array(PluginSchema).or(z.array(z.any())),
    wordpress: WordpressSchema?.or(z.array(z.any())),
  }),
  project_id: z.string().optional(),
  created_at: z.string().regex(dateRegex).optional(),
  updated_at: z.string().regex(dateRegex),
});

export const SiteFormSchema = z.object({
  id: z.string(),
  user_id: z.string(),
  client_id: z.string().nonempty(),
  name: z.string().nonempty(),
  domain: z.string().nonempty(),
  api_key: z.string().nonempty(),
  project_id: z.string().optional(),
});

export const CreateSiteResponseSchema = SiteObjectSchema;

export const GetSitesResponseSchema = z.object({
  data: z.array(SiteObjectSchema),
  links: LinksSchema,
  meta: MetaSchema,
});

export const GetSitesListSchema = z.array(SiteObjectSchema);

export const GetSitesRequestSchema = GetListingRequestSchema;

export const SiteObjectIdSchema = z.object({
  id: z.string(),
});

export const SiteUserObjectIdsSchema = z.object({
  site_id: z.string(),
  user_id: z.string(),
});

export type SiteSelect = { id: string; name: string };
export type SiteObject = z.infer<typeof SiteObjectSchema>;
export type SiteFormObject = z.infer<typeof SiteFormSchema>;
export type GetSitesRequest = z.infer<typeof GetSitesRequestSchema>;
export type GetSitesResponse = z.infer<typeof GetSitesResponseSchema>;
export type SiteObjectId = z.infer<typeof SiteObjectIdSchema>;
export type SiteUserObjectIds = z.infer<typeof SiteUserObjectIdsSchema>;
export type PluginObject = z.infer<typeof PluginSchema>;
export type WordpressObject = z.infer<typeof WordpressSchema>;

export function getSites(data: GetSitesRequest) {
  return new HttpClient().request<GetSitesResponse>({
    method: "get",
    url: "/sites",
    validator: GetSitesResponseSchema,
    params: data,
  });
}

export function getSitesByClientId(clientId: string) {
  return new HttpClient().request<SiteObject[]>({
    method: "get",
    url: "/sites/client/" + clientId,
    validator: GetSitesListSchema,
  });
}

export function newSite(data: Omit<SiteFormObject, "id">) {
  return new HttpClient().request<SiteObject>({
    method: "post",
    url: "/sites",
    validator: SiteObjectSchema,
    data,
  });
}

export function getSite(data: SiteObjectId) {
  return new HttpClient().request<SiteObject>({
    method: "get",
    url: `/sites/${data.id}`,
    validator: SiteObjectSchema,
  });
}

export function deleteSite(data: SiteObjectId) {
  return new HttpClient().request({
    method: "delete",
    url: `/sites/${data.id}`,
    data,
  });
}

export function syncSite(data: SiteUserObjectIds) {
  return new HttpClient().request({
    method: "post",
    url: `/sites/${data.site_id}/sync`,
    data,
  });
}

export function updateSite(data: SiteFormObject, site_id: string) {
  return new HttpClient().request<SiteObject>({
    method: "put",
    url: `/sites/${site_id}`,
    data,
  });
}

export function selectSiteId(sites?: SiteObject[]): SiteSelect[] {
  let sitesList: SiteSelect[] = [];

  sites?.forEach((site) => {
    let userSelect = {
      id: site.id,
      name: site.name
    }
    sitesList.push(userSelect);
  });
  return sitesList;
}

export function mockSiteIssue() {
  return new HttpClient().request<SiteObject>({
    method: "post",
    url: `/sites/mockIssue`,
  });
}
