import * as React from "react";

import { ReactComponent as IconTrash } from "assets/svg/trash.svg";
import { ReactComponent as IconClose } from "assets/svg/close.svg";

import api from "api";
import { useEffect, useState } from "react";
import { Form, FormNotification } from "components/form/form";
import { FormFieldVariant } from "components/form/common";
import InputField, { InputFieldType } from "components/form/input";
import { SubmitButton } from "components/form/submit-button";
import { useParams, useHistory } from "react-router-dom";
import _, { update } from "lodash";
import { debug } from "utils/debug";
import DeleteConfirmationAlert, {
  DeleteConfirmationAlertType,
} from "components/form/delete-confirmation";
import { store } from "store";
import { SiteFormObject, SiteFormSchema, SiteObject } from "api/endpoints/sites";
import {
  selectClientId
} from "api/endpoints/clients";
import formatDate from "utils/date";
import { EditModalSkeleton } from "./skeletons/edit-modal-skeleton";
import { Multiselect } from "components/controls/multiselect-control";
import classNames from "classnames";
import { Field } from "formik";
import useRequest from "api/use-request";

export interface ISiteDetailsProps {
  isNew: boolean;
  nextLocation: string;
  onUpdate: () => void;
}

function newSiteObj(userId: string): SiteFormObject {
  return _.clone({
    id: "",
    user_id: userId,
    client_id: "",
    name: "",
    domain: "",
    api_key: "",
    project_id: "",
  });
}

export function SiteDetails({ isNew, nextLocation, onUpdate }: ISiteDetailsProps) {
  let [initialValue, setInitialValue] = useState<SiteFormObject>();
  let [isDeleting, setIsDeleting] = useState(false);
  let [isUploading, setIsUploading] = useState(false);
  let [editedSite, setEditedSite] = useState({} as SiteObject);
  let [disableSubmit, setDisableSubmit] = useState(true);

  let user = useRequest(api.users.getCurrentUser());
  let userId = (user.data ? user.data.id : "");

  let validationSchema = SiteFormSchema.omit({
    id: true,
    project_id: true,
  }).nonstrict();

  let history = useHistory();
  let { siteId } = useParams() as {
    siteId: string | undefined;
  };

  useEffect(() => {
    if (initialValue) {
      setDisableSubmit(initialValue.client_id == "" ? true : false);
    }
  }, [initialValue]);

  const submitHandler = (values: any, formikHelpers: any) => {
    const payload: SiteFormObject = {
      id: siteId ? siteId : "",
      user_id: values.user_id,
      client_id: values.client_id,
      name: values.name,
      domain: values.domain,
      api_key: values.api_key,
      project_id: values.project_id,
    };

    let update: Promise<SiteObject>;
    if (isNew) {
      update = api.sites.newSite(payload).fetch();
    } else {
      console.log("payload: " + JSON.stringify(payload));
      update = api.sites.updateSite(payload, siteId ?? "").fetch();
    }

    return update.then((response) => {
      store.notifications.presentNotification("Site changes were saved.");
      onUpdate();
      if (nextLocation !== "") {
        nextLocation = nextLocation.replace(":siteId", response.id);
        history.replace(nextLocation);
      } else {
        history.goBack();
      }
    });
  };

  const loadSite = async (siteId: string) => {
    let site = await api.sites.getSite({ id: siteId }).fetch();
    if (site) {
      var auxSite = site;
      auxSite.user_id = userId;
      console.log(auxSite);
      setEditedSite(auxSite);
      setInitialValue(auxSite);
    }
  };

  const initForm = () => {
    if (!siteId) {
      setInitialValue(newSiteObj(userId));
    } else {
      loadSite(siteId);
    }
  };

  useEffect(initForm, []);

  useEffect(() => {
    document.body.classList.add("has-modal-open");
    return () => {
      document.body.classList.remove("has-modal-open");
    };
  });

  function deleteEditedSite() {
    if (siteId) {
      let update = api.sites.deleteSite({ id: siteId }).fetch();
      onUpdate();
      update
        .then((response) => {
          setIsDeleting(false);
          let mainViewLink = '/sites';
          history.push(mainViewLink);
        })
        .catch((error) => {
          setIsDeleting(false);
          debug(error);
        });
    }
  }

  function closeModal() {
    history.goBack();
  }

  let data = {};
  let clients = useRequest(api.clients.getClients(data));
  let clientsData = clients.data?.data;

  return (
    <>
      {initialValue ? (
        <div className="c-modal__root">
          <div className="c-modal__wrapper c-modal__wrapper--md">
            <div className="c-modal c-modal--overflow">
              <div className="c-modal__header">
                <div className="c-block c-block--spacing-t-extra-small">
                  <div className="o-container-fluid">
                    <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                      <div className="o-col">
                        <div
                          onClick={() => {
                            closeModal();
                          }}
                          className="c-link-cta-basic"
                        >
                          <span>Close</span>
                          <IconClose className="o-svg-icon" />
                        </div>
                      </div>
                      <div className="o-col u-flex-grow">
                        <p className="c-modal__headline">{isNew ? "Add" : "Edit"} Security Monitor</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="c-modal__main">
                <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
                  <div className="o-container-fluid">
                    <Form
                      validationSchema={validationSchema}
                      initialValues={initialValue}
                      onSubmit={submitHandler}
                      enableReinitialize={true}
                    >
                      {({ values, errors, setFieldValue }) => (
                        <div className="o-row">
                          <div className="o-col-6@sm o-col-7@md o-col-7@lg">
                            <FormNotification />
                            <div className="o-row">
                              <div className="o-col-6@lg o-col-10@xl">
                                <div className="u-mb-spacer-base-large">
                                  <h6>Site Info</h6>
                                  <div className="o-row o-row--small-gutters">

                                    <div className="o-col-12">
                                      <label htmlFor={"role"} className={classNames("c-form-label")}>
                                        Client
                                      </label>
                                      <Field
                                        name="client_id"
                                        id="client_id"
                                        component={Multiselect}
                                        placeholder="Client"
                                        options={selectClientId(
                                          clientsData
                                        ).map((client) => {
                                          return { value: client.id, label: client.name };
                                        })}
                                        isMulti={false}
                                        onChange={(selectedValue: string) => {
                                          setDisableSubmit(selectedValue == "" ? true : false);
                                        }}
                                      />
                                      <br />
                                    </div>

                                    <div className="o-col-12">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`name`}
                                        placeholder="Name"
                                        label="Name"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                    <div className="o-col-12">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`domain`}
                                        placeholder="Domain"
                                        label="Domain"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                    <div className="o-col-12">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`api_key`}
                                        placeholder="Api Key"
                                        label="Api Key"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                    <div className="o-col-12">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`project_id`}
                                        placeholder="Project Id"
                                        label="Project Id (optional)"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="o-col-6@sm o-col-5@md o-col-5@lg">
                            <div className="c-card c-card--bg-light">
                              <div className="c-card__body">
                                <div className="c-card__header">
                                  <h6>{isNew ? "Create" : "Update"}</h6>
                                  {!isNew && (
                                    <div className="c-card__desc">
                                      {editedSite && editedSite.updated_at ? (
                                        <p>
                                          This site was last updated{" "}
                                          {formatDate(editedSite.updated_at)}.
                                        </p>
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="o-row o-row--fluid c-button-group">
                                  <div className="o-col">
                                    <SubmitButton disabled={disableSubmit}>
                                      <span>{isNew ? "Create" : "Update"} site</span>
                                    </SubmitButton>
                                  </div>
                                  {!isNew && (
                                    <div className="o-col c-button-group__inline">
                                      <div
                                        className="c-link-cta-basic"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          setIsDeleting(true);
                                        }}
                                      >
                                        <IconTrash className="o-svg-icon" />
                                        <span>Delete site</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <DeleteConfirmationAlert
                                onDelete={deleteEditedSite}
                                onCancel={() => {
                                  setIsDeleting(false);
                                }}
                                resource_label={editedSite.name}
                                show={isDeleting}
                                type={DeleteConfirmationAlertType.Card}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EditModalSkeleton />
      )}
    </>
  );
}
