import { Field as FormikField, FieldConfig, useFormikContext } from 'formik';
import * as React from 'react';
import classNames from 'classnames';
import { useEffect, useState } from "react";

type InputFieldProps<T> = JSX.IntrinsicElements['input'] & {
  toggleName: string;
  label: string;
  option1: string;
  option2: string;
  setSelector: React.Dispatch<React.SetStateAction<boolean>>;
  selector: boolean;
}

export const ToggleField = <T extends any>({
  toggleName,
  label,
  option1,
  option2,
  setSelector,
  selector
}: InputFieldProps<T>): React.ReactElement => {


  const animateToggle = (e: React.MouseEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    target.value === "true" ? setSelector(true) : setSelector(false);
  };

  return (
    <div className="c-form-element--toggle">
      <label htmlFor={label} className={classNames('c-form-label')}>{label}</label>

      <div role="group" className={classNames('c-form-element--toggle-wrapper')} >
        <div className={classNames('c-form-element--toggle-options')}>
          <div className={classNames('c-form-element--toggle-selector')} style={{ transform: `translateX(${selector ? "0px" : "calc(100% + 4px)"})` }} />

          <label className={classNames('c-form-element--toggle-field', (selector ? "selected" : ""))}>
            <FormikField type="radio" name={toggleName} value="true"
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                animateToggle(e);
              }} />
            {option1}
          </label>

          <label className={classNames('c-form-element--toggle-field', (!selector ? "selected" : ""))}>
            <FormikField type="radio" name={toggleName} value="false"
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                animateToggle(e);
              }} />
            {option2}
          </label>

        </div>
      </div>
    </div>
  );
};

export default ToggleField;
