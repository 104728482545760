import * as React from "react";

import { ReactComponent as IconTrash } from "assets/svg/trash.svg";
import { ReactComponent as IconClose } from "assets/svg/close.svg";
import { ReactComponent as IconAlertSuccess } from "assets/svg/alert-success.svg";

import api from "api";
import {
  CertificateObject,
  CertificateFormSchema,
  CertificateFormObject,
} from "api/endpoints/certificates";
import {
  selectClientId
} from "api/endpoints/clients";
import {
  selectSiteId
} from "api/endpoints/sites";
import { useEffect, useState } from "react";
import useRequest from "api/use-request";
import { Form, FormNotification } from "components/form/form";
import { FormFieldVariant } from "components/form/common";
import InputField, { InputFieldType } from "components/form/input";
import ToggleField from "components/form/toggle";
import { CheckboxField } from "components/form/checkbox";
import { SubmitButton } from "components/form/submit-button";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";
import { debug } from "utils/debug";
import DeleteConfirmationAlert, {
  DeleteConfirmationAlertType,
} from "components/form/delete-confirmation";
import { Multiselect } from "components/controls/multiselect-control";
import { Field } from "formik";
import classNames from "classnames";
import { store } from "store";
import { SendResetLinkRequest } from "api/endpoints/password";
import { EditModalSkeleton } from "./skeletons/edit-modal-skeleton";
import formatDate from "utils/date";

export interface ICertificateDetailsProps {
  isNew: boolean;
  nextLocation: string;
  onUpdate: () => void;
}

function newCertificateObj(): CertificateFormObject {
  return _.clone({
    id: "",
    client_id: "",
    site_id: "",
    hostname: "",
    active: true,
    valid: true,
    issuer: "",
    signature: "",
    expiration_at: "",
    validation_at: ""
  });
}

export function CertificateDetails({ isNew, nextLocation, onUpdate }: ICertificateDetailsProps) {
  let [initialValue, setInitialValue] = useState<CertificateFormObject>();
  let [isUploading, setIsUploading] = useState(false);
  let [isDeleting, setIsDeleting] = useState(false);
  let [disableSubmit, setDisableSubmit] = useState(true);
  let [shouldOpenBrowseDialog, setShouldOpenBrowseDialog] = useState(false);
  let [uploadedFilePath, setUploadedFilePath] = useState("");
  let [uploadingFileName, setUploadingFileName] = useState("");
  let [editedCertificate, setEditedCertificate] = useState({} as CertificateObject);
  let [passwordResetLinkSent, setPasswordResetLinkSent] = useState(false);
  let [date, setDate] = useState(new Date());
  let [selectedClient, setSelectedClient] = useState(editedCertificate.client_id);
  let [selectedSite, setSelectedSite] = useState(editedCertificate.site_id);
  let [hasSite, setHasSite] = useState(isNew ? true : (editedCertificate.site_id ? true : false));

  let validationSchema = CertificateFormSchema.omit({
    id: true,
    hostname: true,
    site_id: true,
    issuer: true,
    signature: true,
    expiration_at: true,
    validation_at: true
  }).nonstrict();

  let history = useHistory();
  let { certificateId } = useParams() as {
    certificateId: string | undefined;
  };

  useEffect(() => {
    if (initialValue) {
      setSelectedClient(initialValue.client_id!);
      setSelectedSite(initialValue.site_id!);
      setDisableSubmit(!initialValue.client_id || initialValue.client_id == "" ? true
        : (initialValue.site_id! && (!initialValue.site_id || initialValue.site_id == "-" || initialValue.site_id == "") ? true : false));
    }
  }, [initialValue]);

  useEffect(() => {
    setDisableSubmit(!selectedClient || selectedClient == "" ? true
      : (hasSite && (!selectedSite || selectedSite == "-" || selectedSite == "") ? true : false));
  }, [hasSite, selectedClient, selectedSite]);

  const submitHandler = (values: any, formikHelpers: any) => {
    const payload: CertificateFormObject = {
      id: certificateId ? certificateId : "",
      client_id: values.client_id,
      site_id: hasSite ? values.site_id : "-",
      hostname: hasSite ? "-" : (values.hostname ? values.hostname : "(empty)"),
      issuer: "-",
      signature: "-",
      expiration_at: "1970-01-01 00:00:00",
      validation_at: "1970-01-01 00:00:00",
      active: true,
      valid: true
    };

    let update: Promise<CertificateObject>;
    if (isNew) {
      update = api.certificates.newCertificate(payload).fetch();
    } else {
      update = api.certificates.updateCertificate(payload, certificateId ?? "").fetch();
    }

    return update.then((response) => {
      store.notifications.presentNotification("Certificate changes were saved.");
      onUpdate();

      if (nextLocation !== "") {
        nextLocation = nextLocation.replace(":certificateId", response.id);
        history.replace(nextLocation);
      } else {
        history.goBack();
      }
    });
  };

  const loadCertificate = async (certificateId: string) => {
    let certificate = await api.certificates.getCertificate({ id: certificateId }).fetch();
    if (certificate) {
      setEditedCertificate(certificate);
      setHasSite(certificate.site_id != "-" ? true : false)
      setInitialValue(certificate);
    }
  };

  const initForm = () => {
    if (!certificateId) {
      setInitialValue(newCertificateObj());
    } else {
      loadCertificate(certificateId);
    }
  };

  useEffect(initForm, []);

  useEffect(() => {
    document.body.classList.add("has-modal-open");
    return () => {
      document.body.classList.remove("has-modal-open");
    };
  });

  function deleteEditedCertificate() {
    if (certificateId) {
      let update = api.certificates.deleteCertificate({ id: certificateId }).fetch();
      onUpdate();
      update
        .then((response) => {
          setIsDeleting(false);
          let mainViewLink = '/certificates';
          history.push(mainViewLink);
        })
        .catch((error) => {
          setIsDeleting(false);
          debug(error);
        });
    }
  }

  function closeModal() {
    history.goBack();
  }

  let data = {};
  let clients = useRequest(api.clients.getClients(data));
  let clientsData = clients.data?.data;

  let sites = useRequest(api.sites.getSites(data));
  let sitesData = sites.data?.data;

  return (
    <>
      {initialValue ? (
        <div className="c-modal__root">
          <div className="c-modal__wrapper c-modal__wrapper--md">
            <div className="c-modal c-modal--overflow">
              <div className="c-modal__header">
                <div className="c-block c-block--spacing-t-extra-small">
                  <div className="o-container-fluid">
                    <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                      <div className="o-col">
                        <div
                          onClick={() => {
                            closeModal();
                          }}
                          className="c-link-cta-basic"
                        >
                          <span>Close</span>
                          <IconClose className="o-svg-icon" />
                        </div>
                      </div>
                      <div className="o-col u-flex-grow">
                        <p className="c-modal__headline">{isNew ? "Add" : "Edit"} Certificate Monitor</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="c-modal__main">
                <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
                  <div className="o-container-fluid">
                    <Form
                      validationSchema={validationSchema}
                      initialValues={initialValue}
                      onSubmit={submitHandler}
                      enableReinitialize={true}
                    >
                      {({ values, errors, setFieldValue }) => (
                        <div className="o-row">
                          <div className="o-col-6@sm o-col-7@md o-col-7@lg">
                            <FormNotification />
                            <div className="o-row">
                              <div className="o-col-6@lg o-col-10@xl">
                                <div className="u-mb-spacer-base-large">
                                  <h6>Domain Info</h6>
                                  <div className="o-row o-row--small-gutters">

                                    <div className="o-col-12">
                                      <label htmlFor={"role"} className={classNames("c-form-label")}>
                                        Client
                                      </label>
                                      <Field
                                        name="client_id"
                                        id="client_id"
                                        component={Multiselect}
                                        placeholder="Client"
                                        options={selectClientId(
                                          clientsData
                                        ).map((client) => {
                                          return { value: client.id, label: client.name };
                                        })}
                                        isMulti={false}
                                        onChange={(selectedValue: string) => {
                                          setSelectedClient(selectedValue);
                                        }}
                                      />
                                      <br />
                                    </div>

                                    <div className="o-col-8">
                                      <ToggleField
                                        toggleName="SiteToggle"
                                        label="Link to:"
                                        option1="Site"
                                        option2="New Domain"
                                        setSelector={setHasSite}
                                        selector={hasSite}
                                      />
                                    </div>

                                    <div className="toggle-fields-container">
                                      <div className={`o-col-12 toggle-field ${!hasSite ? "field-invisible" : ""}`}>
                                        <label className={classNames("c-form-label")}>
                                          Site
                                        </label>
                                        <Field
                                          name="site_id"
                                          id="site_id"
                                          component={Multiselect}
                                          placeholder="Site"
                                          options={selectSiteId(
                                            sitesData
                                          ).map((site) => {
                                            return { value: site.id, label: site.name };
                                          })}
                                          isMulti={false}
                                          onChange={(selectedValue: string) => {
                                            setSelectedSite(selectedValue);
                                          }}
                                        />
                                        <br />
                                      </div>

                                      <div className={`o-col-12 toggle-field ${hasSite ? "field-invisible" : ""}`}>
                                        <InputField
                                          type={InputFieldType.text}
                                          name={`hostname`}
                                          placeholder="Hostname"
                                          label="Hostname"
                                          variant={FormFieldVariant.fill}
                                          autocomplete={false}
                                          required={!hasSite}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="o-col-6@sm o-col-5@md o-col-5@lg">
                            <div className="c-card c-card--bg-light">
                              <div className="c-card__body">
                                <div className="c-card__header">
                                  <h6>{isNew ? "Create" : "Update"}</h6>
                                  {!isNew && (
                                    <div className="c-card__desc">
                                      {editedCertificate && editedCertificate.updated_at ? (
                                        <p>
                                          Last update: {" "}
                                          {formatDate(editedCertificate.updated_at)}.
                                        </p>
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="o-row o-row--fluid c-button-group">
                                  <div className="o-col">
                                    <SubmitButton disabled={disableSubmit}>
                                      <span>{isNew ? "Create" : "Update"} Certificate</span>
                                    </SubmitButton>
                                  </div>
                                  {!isNew && (
                                    <div className="o-col c-button-group__inline">
                                      <div
                                        className="c-link-cta-basic"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          setIsDeleting(true);
                                        }}
                                      >
                                        <IconTrash className="o-svg-icon" />
                                        <span>Delete certificate</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <DeleteConfirmationAlert
                                onDelete={deleteEditedCertificate}
                                onCancel={() => {
                                  setIsDeleting(false);
                                }}
                                resource_label={editedCertificate.issuer}
                                show={isDeleting}
                                type={DeleteConfirmationAlertType.Card}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EditModalSkeleton />
      )}
    </>
  );
}
