import { Instance, setLivelinessChecking, types } from "mobx-state-tree";

export const AppError = types.model({
  identifier: types.union(types.undefined, types.string),
  type: types.enumeration(["network", "permission", "version"]),
  style: types.maybe(types.union(types.literal("error"), types.literal("warning"))),
  message: types.string,
  cta: types.maybe(types.union(types.literal("reload"))),
});

interface IAppError extends Instance<typeof AppError> {}

export const Errors = types
  .model({
    app: types.array(AppError),
  })
  .views((self) => ({
    get hasError() {
      return true;
    },
  }))
  .actions((self) => ({
    presentError: function (error: IAppError) {
      self.app.push(error);
    },

    presentUniqueError: function (
      identifier: string,
      message: string,
      type: "network" | "permission" | "version",
      style: "error" | "warning" = "error"
    ) {
      const index = self.app.findIndex((error) => error.identifier === identifier);
      if (index !== -1) {
        self.app[index] = {
          identifier,
          style,
          type,
          message,
        } as IAppError;
      } else {
        self.app.push({
          identifier,
          style,
          type,
          message,
        });
      }
    },

    presentNetworkError: function (message: string, cta?: string) {
      if (!self.app.find(({ type }) => type === "network")) {
        self.app.push({
          type: "network",
          message,
          cta,
        });
      }
    },

    clearNetworkError: function () {
      const index = self.app.findIndex((error) => error.type === "network");
      if (index !== -1) {
        self.app.splice(index, 1);
      }
    },
  }));

export default Errors;
