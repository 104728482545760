import * as React from "react";

import { Switch, Route, useParams } from "react-router-dom";
import { ClientDetails } from "components/cards/client-edit";
import { useStore } from "store";

export interface IClientEditModalProps {}

const ClientEditModal: React.FunctionComponent<IClientEditModalProps> = () => {
  const { refreshs } = useStore();

  function onUpdate() {
    refreshs.setClientsRefresh(true);
  }

  return (
    <Switch>
      <Route path={`/clients/:clientId/edit`}>
        <ClientDetails isNew={false} nextLocation={`/clients`} onUpdate={onUpdate} />
      </Route>
      <Route path={`/clients/new`}>
        <ClientDetails isNew={true} nextLocation={`/clients`} onUpdate={onUpdate} />
      </Route>
      <Route path={`/clients/:clientId/view/edit`}>
        <ClientDetails isNew={false} nextLocation={`/clients/:clientId/view`} onUpdate={onUpdate} />
      </Route>
    </Switch>
  );
};

export default ClientEditModal;
