import React, { useEffect } from 'react';
import Routes from 'routes';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import config from 'config';

Sentry.init({
  dsn: config.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: config.APP_ENVIRONMENT,
  release: config.APP_VERSION,
  tracesSampleRate: config.APP_ENVIRONMENT === 'production'? 0.05 : 0.25,
});

function App() {
  useEffect(() => {
    window.loader(false);
  }, []);
  return (
    <Routes />
  );
}

export default App;
