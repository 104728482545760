import * as React from "react";

// Components
import Listing from "components/cards/listing";
import {
  listingContext,
  ListingSortOption,
  ListingType,
  SortDirection,
} from "components/cards/listing-context";

import api from "api";
import useRequest from "api/use-request";
import { useState, Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useStore } from "store";
import ListingCard from "./listing-card";
import ClientCardContent from "./client-card-content";
import AddNewBlock from "./add-new";

export interface IClientsListingProps {
  showAllClients: boolean;
  clientId: string;
}

const ClientsListing: React.FunctionComponent<IClientsListingProps> = ({ showAllClients, clientId }) => {
  let location = useLocation();
  const { refreshs, errors } = useStore();
  let searchArgs = queryString.parse(location.search, { parseNumbers: true });
  if (searchArgs.limit === undefined) {
    searchArgs.limit = showAllClients ? 48 : 12;
  }

  let [selection, setSelection] = useState<string[]>([]);

  let clients = useRequest(api.clients.getClients(searchArgs));

  let sort_options: Array<ListingSortOption> = [
    { key: "name", title: "Name - Asc", direction: SortDirection.Ascending },
    { key: "name", title: "Name - Desc", direction: SortDirection.Descending },
    { key: "url", title: "Url - Asc", direction: SortDirection.Ascending },
    { key: "url", title: "Url - Desc", direction: SortDirection.Descending },
  ];

  function deleteClients(ids: string[]) {
    let updates: Promise<unknown>[] = [];
    ids.forEach((id) => {
      updates.push(api.clients.deleteClient({ id }).fetch());
    });
    Promise.all(updates).finally(() => {
      clients.mutate();
      setSelection([]);
    });
  }

  function toggleSelectClients(ids: string[]) {
    ids.forEach((id) => {
      const index = selection.indexOf(id);
      if (index > -1) {
        selection.splice(index, 1);
      } else {
        selection.push(id);
      }
    });
    setSelection([...selection]);
  }

  function selectAll(shouldSelect: boolean) {
    if (clients.data && clients.data?.data.length > 0) {
      clients.data?.data.forEach((client) => {
        const index = selection.indexOf(client.id);
        if (shouldSelect && index < 0) {
          selection.push(client.id);
        }
        if (!shouldSelect && index > -1) {
          selection.splice(index, 1);
        }
      });
      setSelection([...selection]);
    }
  }

  useEffect(() => {
    if (refreshs.clients) {
      clients.mutate();
      refreshs.setClientsRefresh(false);
    }
  }, [refreshs.clients]);

  return (
    <>
      {clients.data && clients.data?.data.length < 1 && !searchArgs.q ? (
        <AddNewBlock
          title="Add new Client"
          subtitle="You currently don’t have any client added, click here to start."
        />
      ) : (
        <>
          <listingContext.Provider value={{ ...clients, selection, sort_options }}>
            <Suspense fallback={<div>Loading...</div>}>
              <Listing
                deleteHandler={deleteClients}
                selectionHandler={toggleSelectClients}
                selectAllHandler={selectAll}
                defaultListingType={showAllClients ? ListingType.list : ListingType.grid}
                defaultLimit={showAllClients ? 48 : 12}
                shouldShowSearch={true}
                settingsKey={showAllClients ? "AllClients" : "Clients"}
              >
                {clients.data?.data.map((client) => (
                  <ListingCard
                    deleteHandler={deleteClients}
                    selectHandler={toggleSelectClients}
                    key={client.id}
                    itemId={client.id}
                    itemLabel={client.url}
                    itemName="Client"
                    showView={true}
                  >
                    <ClientCardContent client={client} />
                  </ListingCard>
                ))}
              </Listing>
            </Suspense>
          </listingContext.Provider>
        </>
      )}
    </>
  );
};

export default ClientsListing;
