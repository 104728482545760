import * as auth from "./endpoints/auth";
import * as users from "./endpoints/users";
import * as sites from "./endpoints/sites";
import * as certificates from "./endpoints/certificates";
import * as clients from "./endpoints/clients";
import * as password from "./endpoints/password";
import * as service from "./endpoints/service";

export default {
  auth,
  users,
  sites,
  certificates,
  clients,
  password,
  service,
};
