import * as React from "react";

import { SiteObject } from "api/endpoints/sites";
import { Link, useLocation } from "react-router-dom";
import * as quartzite from "quartzite";
import { isArray } from "lodash";
import formatDate from "utils/date";

interface ISiteCardContentProps {
  site: SiteObject;
}

const SiteCardContent: React.FunctionComponent<ISiteCardContentProps> = ({ site }) => {
  let location = useLocation();
  let editSiteLink = location.pathname + `/${site.id}/edit`;

  const wpVersionStatus = (site: SiteObject): string => {
    if (isArray(site.wp_version) && site.wp_version.length == 0) {
      return "Unknown WP version!";
    } else {
      return site.wp_version.current_wp_version === site.wp_version.latest_wp_version
        ? "Latest WP version"
        : "Wordpress update available";
    }
  };

  const criticalUpdates = (site: SiteObject): string => {
    if (site.critical.plugins.length > 0) {
      return `, ${site.critical.plugins.length} critical`;
    }
    return "";
  };

  return (
    <div className="c-card__content">
      <p className="c-card__title">
        <Link to={editSiteLink}>{site.name}</Link>
      </p>
      <div className="c-card__info">
        <p>
          <strong>{site.plugins?.length}</strong> plugin updates {criticalUpdates(site)}
        </p>
        <p>{wpVersionStatus(site)}</p>
        <p>
          Last update: <strong>{formatDate(site.updated_at)}</strong>
        </p>
      </div>
    </div>
  );
};

export default SiteCardContent;
