import Skeleton from "react-loading-skeleton";

export function EditModalSkeleton() {
  return (
    <div className="c-modal__root">
      <div className="c-modal__wrapper c-modal__wrapper--lg">
        <div className="c-modal c-modal--overflow">
          <div className="c-modal__header">
            <div className="c-block c-block--spacing-t-extra-small">
              <div className="o-container-fluid">
                <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                  <div className="o-col">
                    <Skeleton width={250} />
                  </div>
                  <div className="o-col u-flex-grow">
                    <p className="c-modal__headline">
                      <Skeleton width={250} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
              <div className="o-container-fluid">
                <div className="o-row">
                  <div className="o-col-6@sm o-col-7@md o-col-8@lg">
                    <div className="o-row">
                      <div className="o-col-6@lg o-col-7@xl">
                        <div className="u-mb-spacer-base-large">
                          <h6>
                            <Skeleton width={250} />
                          </h6>
                          <div className="o-row o-row--small-gutters">
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={200} height={60} />
                            </div>
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={200} height={60} />
                            </div>
                            <div className="o-col-12">
                              <Skeleton width={420} height={60} />
                            </div>
                          </div>
                        </div>
                        <div className="u-mb-spacer-base-large">
                          <h6>
                            <Skeleton width={200} />
                          </h6>
                          <div className="o-row o-row--small-gutters">
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={200} height={50} />
                            </div>
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={200} height={50} />
                            </div>
                          </div>
                          <p className="c-note">
                            <Skeleton count={2} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="o-col-6@sm o-col-5@md o-col-4@lg">
                    <div className="c-card c-card--bg-light">
                      <div className="c-card__body">
                        <div className="c-card__header">
                          <h6>
                            <Skeleton width={250} />
                          </h6>
                          <div className="c-card__desc">
                            <p>
                              <Skeleton count={2} />
                            </p>
                          </div>
                        </div>
                        <div className="o-row o-row--fluid c-button-group">
                          <div className="o-col">
                            <Skeleton width={250} height={50} />
                          </div>
                          <div className="o-col c-button-group__inline">
                            <Skeleton width={250} height={50} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
