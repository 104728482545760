import * as React from 'react';
import { Route, RouteProps, RouteComponentProps, RouteChildrenProps } from 'react-router';
import isFunction from 'lodash/isFunction';

export interface GuardedRouteProps extends RouteProps {
  guard: (params: RouteChildrenProps, children: () => React.ReactNode) => React.ReactNode;
}

export function GuardedRoute<
  Path extends string = string,
>({ children, render, component: Component, guard, ...rest }: GuardedRouteProps) {
  if (Component) {
    return (
      <Route
        {...rest}
        render={(params) => (
          <>
            {guard(params as unknown as RouteChildrenProps, () => (
              <Component {...params} />
            ))}
          </>
        )}
      />
    );
  }

  if (render) {
    return (
      <Route
        {...rest}
        render={(params) => (
          <>
            {guard(params as unknown as RouteComponentProps, () =>
              render(params as unknown as RouteComponentProps)
            )}
          </>
        )}
      />
    );
  }

  return (
    <Route {...rest}>
      {(params) => (
        <>
          {guard(params as unknown as RouteChildrenProps, () =>
            isFunction(children) ? children(params as unknown as RouteChildrenProps) : children
          )}
        </>
      )}
    </Route>
  );
}

export default GuardedRoute;
