import { types } from "mobx-state-tree";

export const Refreshs = types
  .model({
    users: false,
    sites: false,
    certificates: false,
    clients: false,
  })
  .views((self) => ({
    get usersNeedsRefresh() {
      return self.users;
    },
    get sitesNeedsRefresh() {
      return self.sites;
    },
  }))
  .actions((self) => ({
    setUsersRefresh: function (status: boolean) {
      self.users = status;
    },
    setSitesRefresh: function (status: boolean) {
      self.sites = status;
    },
    setCertificatesRefresh: function (status: boolean) {
      self.certificates = status;
    },
    setClientsRefresh: function (status: boolean) {
      self.clients = status;
    },
  }));

export default Refreshs;
