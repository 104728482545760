import * as React from "react";

import api from "api";
import { SiteObject, PluginObject, WordpressObject } from "api/endpoints/sites";
import { ClientObject } from "api/endpoints/clients";
import { useContext, useEffect, useState, Suspense } from "react";
import { PageContext } from "components/layout/page-context";
import Notification from "components/notification";
import { useParams, useHistory } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import formatDate from "utils/date";
import DeleteConfirmationAlert, {
  DeleteConfirmationAlertType,
} from "components/form/delete-confirmation";
import { debug } from "utils/debug";
import { useStore } from "store";
import useRequest from 'api/use-request';

// Components
import Table from "components/cards/table";
import TableCard from "components/cards/table-card";
import PluginCard from "components/cards/plugin-card";

// Icons
import { ReactComponent as IconPen } from "assets/svg/pen.svg";
import { ReactComponent as IconSync } from "assets/svg/refresh.svg";
import { ReactComponent as IconTrash } from "assets/svg/trash.svg";
import { ReactComponent as IconPlugin } from "assets/svg/plugin.svg";
import { ReactComponent as IconCritical } from "assets/svg/critical.svg";
import { ReactComponent as IconAttention } from "assets/svg/attention.svg";
import { ReactComponent as IconThumbUp } from "assets/svg/thumb-up.svg";
import { ReactComponent as IconWordpress } from "assets/svg/wordpress.svg";


const SiteDetailsPage: React.FunctionComponent = () => {
  let user = useRequest(api.users.getCurrentUser());

  const page = useContext(PageContext);
  const { refreshs } = useStore();

  const [siteErrors, setSiteErrors] = useState();
  const [siteWarnings, setSiteWarnings] = useState();
  const [showActions, setShowActions] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [errorSyncing, setErrorSyncing] = useState(false);

  const [site, setSite] = useState({} as SiteObject);
  const [client, setClient] = useState({} as ClientObject);

  const [siteName, setSiteName] = useState("Loading...");
  const [url, setUrl] = useState("Loading...");
  const [clientName, setClientName] = useState("Loading...");
  const [updatedDate, setUpdatedDate] = useState("Loading...");
  const [apiKey, setApiKey] = useState("Loading...");
  const [plugins, setPlugins] = useState<any[] | undefined>(undefined);
  const [critical, setCritical] = useState<{ plugins: any[] | PluginObject[]; wordpress: any[] | WordpressObject; }
    | undefined>(undefined);
  const [criticalPlugins, setCriticalPlugins] = useState(0);
  const [wordpressVersion, setWordpressVersion] = useState("Loading...");
  const [wordpressLatestVersion, setWordpressLatestVersion] = useState("Loading...");
  const [wordpressUpdates, setWordpressUpdates] = useState<boolean | undefined>(undefined);
  const [wordpressVulnerabilites, setWordpressVulnerabilites] = useState<boolean | undefined>(undefined);

  let history = useHistory();
  let { siteId } = useParams() as {
    siteId: string;
  };

  let location = useLocation();
  let editLink = location.pathname + "/edit";

  const loadSite = async (siteId: string) => {
    let site = await api.sites.getSite({ id: siteId }).fetch();
    if (site) {
      setSiteName(site.name);
      setUrl(site.domain);
      setUpdatedDate(formatDate(site.updated_at!));
      setApiKey(site.api_key);
      setPlugins(site.plugins);
      setCritical(site.critical);
      setCriticalPlugins(site.critical?.plugins ? site.critical.plugins.length : 0);

      loadWordpressData(site);
      loadClient(site.client_id);
    }
  };

  const loadWordpressData = async (site: SiteObject) => {
    setWordpressVulnerabilites(site.critical?.wordpress
      && typeof site.critical.wordpress === "object"
      && "vulnerabilities" in site.critical.wordpress
      && site.critical.wordpress.vulnerabilities
      && site.critical.wordpress.vulnerabilities.length > 0);

    setWordpressUpdates(site.wp_version?.current_wp_version
      && site.wp_version?.latest_wp_version
      && site.wp_version?.current_wp_version !== site.wp_version?.latest_wp_version);

    setWordpressLatestVersion(site.wp_version?.latest_wp_version ? site.wp_version?.latest_wp_version : "Not found");
    setWordpressVersion(site.wp_version?.current_wp_version ? site.wp_version?.current_wp_version : "Not found");
  };

  const loadClient = async (clientId: string) => {
    let client = await api.clients.getClient({ id: clientId }).fetch();
    if (client) {
      setClient(client);
      setClientName(client.name);
    }
  };

  useEffect(() => {
    page.setTitle("Site Details");
    page.setBackLink("/sites");
    page.setShowsClientSwitcher(false);
    loadSite(siteId);
  }, []);

  useEffect(() => {
    if (refreshs.sites) {
      loadSite(siteId);
      refreshs.setSitesRefresh(false);
    }
  }, [refreshs.sites])

  useEffect(() => {
    if (isSyncing) {
      setIsSyncing(false);
    }
  }, [updatedDate])

  function deleteSite() {
    let update = api.sites.deleteSite({ id: siteId }).fetch();
    update
      .then((response) => {
        setIsDeleting(false);
        history.push("/sites");
      })
      .catch((error) => {
        setIsDeleting(false);
        debug(error);
      });
  }

  function sendMockJiraTask() {
    let update = api.sites.mockSiteIssue().fetch();
    update
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        debug(error);
      });
  }

  function syncSite() {
    setIsSyncing(true);
    setErrorSyncing(false);

    var userId = (user.data ? user.data.id : "");
    let update = api.sites.syncSite({ site_id: siteId, user_id: userId }).fetch();
    update
      .then((response) => {
        refreshs.setSitesRefresh(true);
      })
      .catch((error) => {
        setIsSyncing(false);
        setErrorSyncing(true);
        debug(error);
      });
  }

  let plugin_columns = [
    { id: "plugin_info.name", name: "Name", size: "50%" },
    { id: "plugin_current_version", name: "Current Version", size: "25%" },
    { id: "plugin_new_version", name: "Latest Version", size: "25%" }];

  let plugin_table_limit = 10;

  return (
    <section className="c-block c-block--spacing-b-small">
      <div className="o-container-fluid o-container--full">

        <div className="p-subheader">
          <p className="c-page-subtitle c-title--small">Security Monitoring</p>

          <div className="p-actions">
            <div className="o-col o-col--row p-action--padding-right">
              <div className="c-actions">
                <p className="c-caption">Last Update:</p>
                <p className="c-data">{updatedDate}</p>
              </div>
            </div>
            <div className="p-action-link" onClick={() => {
              syncSite();
            }}>
              <IconSync className="o-svg-icon" />
              <p className="p-action-name">Sync</p>
            </div>
            <div className="p-action-container">
              <Link className="p-action-link" to={editLink}>
                <IconPen className="o-svg-icon" />
                <p className="p-action-name">Edit</p>
              </Link>
            </div>
            <div className="p-action-link" onClick={(event) => {
              event.preventDefault();
              setIsDeleting(true);
            }}>
              <IconTrash className="o-svg-icon" />
              <p className="p-action-name">Delete</p>
            </div>
            <div className="p-action-link" onClick={(event) => {
              event.preventDefault();
              sendMockJiraTask();
            }}>
              <p className="p-action-name">Create Jira Task</p>
            </div>
          </div>
        </div>

        <div className="c-page-title">
          <h2 className="c-title--medium">{siteName}</h2>
        </div>

        {siteErrors && (
          <Notification type={"error"} showsCloseAction>
            <p>
              <strong>Some critical updates are needed!</strong>
            </p>
            <p onClick={() => window.location.reload()}>Click here to review them.</p>
          </Notification>
        )}
        {siteWarnings && (
          <Notification type={"warning"} showsCloseAction>
            <p>
              <strong>Some sites are unreachable</strong>
            </p>
            <p onClick={() => window.location.reload()}>Click here to review them.</p>
          </Notification>
        )}
        {isSyncing && (
          <Notification type={"info"}>
            <p>
              <strong>Syncing site data...</strong>
            </p>
          </Notification>
        )}
        {errorSyncing && (
          <Notification type={"error"}>
            <p>
              <strong>Error syncing site data, try again later.</strong>
            </p>
          </Notification>
        )}

        <div className="o-container--sm">
          <div className="o-row o-row--no-margin">
            <div className="o-col">
              <div className="c-actions">
                <p className="c-caption">URL:</p>
                <p className="c-data">{url}</p>
              </div>
            </div>
            <div className="o-col">
              <div className="c-actions">
                <p className="c-caption">Client:</p>
                <p className="c-data">{clientName}</p>
              </div>
            </div>
            <div className="o-col">
              <div className="c-actions c-actions--double">
                <p className="c-caption">API Key:</p>
                <p className="c-data">{apiKey}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="details-content">
          <div className="c-details-container">
            <div className={"c-detail c-detail--lg " + (criticalPlugins > 0 ? "c-detail--red-background" : "")}>
              <IconPlugin className="c-detail-icon" />
              <div className="c-detail-content">
                <div className="c-detail-content-header">
                  Plugins
                </div>

                {plugins == undefined ? <div className="c-detail-content-text">
                  Loading...
                </div>
                  : (plugins.length > 0 ?
                    ((critical != undefined && critical.plugins.length > 0) ?
                      <div className="c-detail-content-text">
                        <div style={{ float: "left" }}>
                          <strong>{plugins.length} updates available, </strong>
                        </div>
                        <div className="c-detail--red-text" style={{ float: "left", marginLeft: "5px" }}>
                          <strong>{criticalPlugins} critical</strong>
                        </div>
                      </div>
                      : <div className="c-detail-content-text">
                        <strong>{plugins.length} updates available</strong>
                      </div>)
                    : <div className="c-detail-content-text">
                      <strong>All plugins are up to date</strong>
                    </div>)
                }

              </div>
              {critical != undefined && critical.plugins?.length > 0 ?
                <IconCritical className="c-detail-status" />
                : (plugins && plugins.length > 0 ?
                  <IconAttention className="c-detail-status" />
                  : <IconThumbUp className="c-detail-status" />)
              }
            </div>

            <div className={"c-detail c-detail--sm " + (wordpressVulnerabilites ? "c-detail--red-background" : "")}>
              <IconWordpress className="c-detail-icon" />
              <div className="c-detail-content">
                <div className="c-detail-content-header">
                  Wordpress
                </div>

                {wordpressVulnerabilites == undefined ?
                  <div className="c-detail-content-text">
                    <strong>Loading...</strong>
                  </div>
                  : (wordpressVulnerabilites ?
                    <div className="c-detail-content-text c-detail--red-text c-detail--red-background">
                      <strong>Critical update needed</strong>
                    </div>
                    : (wordpressUpdates ?
                      <div className="c-detail-content-text">
                        <strong>Update available</strong>
                      </div>
                      : <div className="c-detail-content-text">
                        <strong>Up to date</strong>
                      </div>))}

              </div>
              {wordpressVulnerabilites ?
                <IconCritical className="c-detail-status" />
                : (wordpressUpdates ?
                  <IconAttention className="c-detail-status" />
                  : <IconThumbUp className="c-detail-status" />)
              }
            </div>
          </div>

          <div className="c-details-container c-details-container--self-start">
            <div className="c-table c-detail--lg">
              <Suspense fallback={<div>Loading...</div>}>
                <Table
                  //selectionHandler={openPluginPopup}
                  columns={plugin_columns}
                  defaultLimit={plugin_table_limit}
                  data={plugins ? plugins : []}
                  setData={setPlugins}
                >
                  {plugins?.map((plugin, index) => (
                    <TableCard
                      key={plugin.plugin_info.slug}
                      itemId={plugin.plugin_info.slug}
                      index={index}
                    >
                      <PluginCard plugin={plugin} critical={critical} />
                    </TableCard>
                  ))}
                </Table>
              </Suspense>
            </div>

            <div className="c-detail-notes c-detail--sm">
              <div className="c-detail-note">
                <div className="c-detail-note-title">Latest Version:</div>
                <div className="c-detail-note-data">{wordpressLatestVersion}</div>
              </div>
              <div className="c-detail-note">
                <div className="c-detail-note-title">Current Version:</div>
                <div className="c-detail-note-data">{wordpressVersion}</div>
              </div>
            </div>
          </div>
        </div>

        <DeleteConfirmationAlert
          onDelete={deleteSite}
          onCancel={() => {
            setIsDeleting(false);
          }}
          resource_label={siteName}
          show={isDeleting}
          type={DeleteConfirmationAlertType.Card}
        />

      </div>
    </section>
  );
};

export default SiteDetailsPage;
