import * as React from 'react';

import Pagination from 'components/cards/pagination';
import { useContext, useEffect, useState, Suspense, ReactChild } from "react";

interface ITableFooterProps {
  defaultLimit: number,
  compact?: boolean,
  data: any[],
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
  currentPage: number
}

const TableFooter: React.FunctionComponent<ITableFooterProps> = ({
  defaultLimit,
  compact,
  data,
  setCurrentPage,
  currentPage
}) => {
  const [total, setTotal] = useState(data.length);
  const [totalPages, setTotalPages] = useState(Math.ceil(data.length / defaultLimit));

  useEffect(() => {
    setTotal(data.length);
    setTotalPages(Math.ceil(data.length / defaultLimit));
  }, [data])

  var firstEntry = 1 + ((currentPage - 1) * defaultLimit);
  var lastEntry = currentPage * defaultLimit;

  return (
    <div className="o-row o-row--fluid c-listing__footer">
      {!compact && (
        <div className="o-col u-justify-start@md">
          <div className="c-actions">
            <div className="c-actions__col">
              {data.length > 0 && (
                <p className="c-caption">{`Showing ${firstEntry} to ${Math.min(lastEntry, total)} of ${total} entries`}</p>
              )}
              {data && data.length === 0 && (
                <p className="c-caption">{`No entries found`}</p>
              )}
            </div>
          </div>
        </div>
      )}
      {data && totalPages > 1 && (
        <div className="o-col-12 o-col@md">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            pageNeighbours={1}
            alwaysShowFirstLast={true}
            alwaysShowPrevNext={true}
            onPageSelect={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TableFooter;
