import * as React from 'react';

import { useDropzone } from "react-dropzone";
import classNames from 'classnames';

import { ReactComponent as IconImage } from 'assets/svg/dropzone-image.svg';
import { useEffect, useState } from 'react';

import api from "api";
import { hash } from "utils/string";

interface IFileDropzoneFieldProps {
  defaultImage: undefined | string,
  setFieldValue: any,
  dropHandler: any,
  label: string,
  shouldOpenBrowseDialog: boolean,
  uploadDirectory: string,
  description?: string;
}

const FileDropzoneField: React.FunctionComponent<IFileDropzoneFieldProps> = (props) => {
  const { setFieldValue, dropHandler, uploadDirectory } = props;
  const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined);

  async function uploadImageToS3(file: File) {
    var index = file.name.indexOf(".");
    var name = file.name.substring(0, index);
    const hashedName = hash(name);
    var extension = file.name.substring(index);

    var newName = hashedName + extension;
    const editedFile = new File([file], newName, { type: file.type });

    var data = {"file": editedFile, "directory": uploadDirectory};

    let response = await api.service.uploadImage(data).fetch();

    setDropError(undefined);
    setFieldValue(response.filename);
    dropHandler(response.filename);
    setSelectedImage(editedFile);
  }

  let [dropError, setDropError] = useState<string>();
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop: acceptedFiles => {
      uploadImageToS3(acceptedFiles[0]);
    },
    onDropRejected: fileRejections => {
      if (fileRejections && fileRejections.length > 0) {
        setDropError(fileRejections[0].errors[0].message);
      }
    },
    maxFiles: 1,
    maxSize: 1 * 1000 * 1000,
  });

  useEffect(() => {
    if (props.shouldOpenBrowseDialog) {
      open();
    }
  }, [props.shouldOpenBrowseDialog]);

  return (
    <>
      <label className={classNames('c-form-label')}>{props.label}</label>
      <div {...getRootProps({ className: "dropzone c-add c-add--link" })}>

        <input {...getInputProps()} />
        {selectedImage !== undefined
          ? <div className="c-thumbnail">
            <img src={`${URL.createObjectURL(selectedImage)}`} alt="" />
          </div>
          : (props.defaultImage !== undefined ?
            <div className="c-thumbnail">
              <img src={props.defaultImage} alt="" />
            </div>
            : <div className="c-add__icon">
              <IconImage className="o-svg-icon" />
            </div>)}

        <div className="c-add__body">
          <div className="c-add__title">
            {isDragActive ? (
              <p>Drop your image here ...</p>
            ) : (
              <p>Drop your image here, or <a href="#" onClick={(e) => { e.preventDefault(); }} className="c-link-cta c-add__link"><span>browse</span></a>.</p>
            )}

          </div>

          {dropError && (
            <ul className="c-form-element--error__list u-block">
              <li>{dropError}</li>
            </ul>
          )}
          {props.description && (
            <div className={`c-add__desc ${dropError ? "u-hidden" : ""}`}>
              <p>{props.description}</p>
            </div>
          )}
        </div>
      </div >
    </>
  );
};

export default FileDropzoneField;
