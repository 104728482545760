import * as React from "react";

import classNames from "classnames";

// Hooks
import { useContext, useRef, useState } from "react";

// Icons
import { ReactComponent as IconPen } from "assets/svg/pen.svg";
import { ReactComponent as IconIn } from "assets/svg/in.svg";
import { ReactComponent as IconEye } from "assets/svg/eye.svg";
import { ReactComponent as IconMore } from "assets/svg/more.svg";
import { ReactComponent as IconTrash } from "assets/svg/trash.svg";

import { Link, useLocation } from "react-router-dom";

import useClickAway from "hooks/use-click-away";
import { listingContext } from "./listing-context";
import * as quartzite from "quartzite";
import DeleteConfirmationAlert, {
  DeleteConfirmationAlertType,
} from "components/form/delete-confirmation";

interface IListingCardProps {
  itemId: string;
  itemLabel: string;
  itemName: string;
  deleteHandler: (ids: string[]) => void;
  selectHandler: (ids: string[]) => void;
  showView: boolean
}

const ListingCard: React.FunctionComponent<IListingCardProps> = ({
  itemId,
  itemLabel,
  itemName,
  deleteHandler,
  selectHandler,
  children,
  showView = true
}) => {
  const classNameSelected = "c-card__link-hidden";

  const [showActions, setShowActions] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { selection } = useContext(listingContext);

  const trigger = useRef<HTMLDivElement>(null);
  useClickAway(trigger, () => setShowActions(false));

  let location = useLocation();
  let editLink = location.pathname + `/${itemId}/edit`;
  let viewLink = location.pathname + `/${itemId}/view`;

  function isSelected(id: string) {
    if (selection && selection.indexOf(id) > -1) {
      return true;
    }
    return false;
  }

  return (
    <div className="c-listing__item">
      <div
        ref={trigger}
        className={classNames([
          "c-card",
          "c-card--link",
          "c-card--bg-white",
          { "is-selected": isSelected(itemId) },
          { "has-popup-open": showActions },
        ])}
      >
        <div className="c-card__body">
          {children}
          <div className="c-card__footer">
            {showView ? (<Link to={viewLink} className="c-link-cta-light">
              <IconIn className="o-svg-icon" />
              <span>View</span>
            </Link>) : ""}
            <div className="c-card__actions c-card__link-hidden">
              <div
                onClick={(event) => setShowActions(!showActions)}
                className="c-card__actions-toggle"
              >
                <IconMore className="o-svg-icon" />
              </div>
              <div className="c-popup">
                <div className="c-popup__header c-popup__header--sm">
                  <p className="c-popup__title">Actions</p>
                </div>
                <div className="c-popup__body">
                  <ul className="c-popup__list c-popup__list--small">
                    {showView ? (<li>
                      <Link to={viewLink}>
                        <IconEye className="o-svg-icon" />
                        <span>View {itemName}</span>
                      </Link>
                    </li>) : ""}
                    <li>
                      <Link to={editLink}>
                        <IconPen className="o-svg-icon" />
                        <span>Edit {itemName}</span>
                      </Link>
                    </li>
                    <li className="c-popup__divider c-popup__divider--top">
                      <div
                        className="c-popup__link"
                        onClick={(event) => {
                          event.preventDefault();
                          setIsDeleting(true);
                          setShowActions(false);
                        }}
                      >
                        <IconTrash className="o-svg-icon" />
                        <span>Delete {itemName}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                "c-form-element",
                "c-form-element--style-fill",
                "c-form-element--checkbox",
                "c-card__checkbox",
                { "c-card__link-hidden": !isSelected(itemId) }
              )}
            >
              <div className="c-form-element__field">
                <input
                  checked={isSelected(itemId)}
                  type="checkbox"
                  id={`check-listing-item-${itemId}`}
                  onChange={(event) => {
                    selectHandler([itemId]);
                  }}
                />
                <label htmlFor={`check-listing-item-${itemId}`}></label>
              </div>
            </div>
          </div>
          <DeleteConfirmationAlert
            onDelete={() => {
              deleteHandler([itemId]);
              setIsDeleting(false);
            }}
            onCancel={() => {
              setIsDeleting(false);
            }}
            resource_label={itemLabel}
            show={isDeleting}
            type={DeleteConfirmationAlertType.Card}
          />
        </div>
      </div>
    </div>
  );
};

export default ListingCard;
