import * as React from "react";
import useClickAway from "hooks/use-click-away";
import { Link } from "react-router-dom";

// Icons
import { ReactComponent as IconMore } from "assets/svg/plus-sign.svg";
import { ReactComponent as IconLess } from "assets/svg/minus-sign.svg";
import { ReactComponent as IconShare } from "assets/svg/share-icon.svg";

// Hooks
import { useContext, useRef, useState } from "react";

import { PluginObject, WordpressObject } from "api/endpoints/sites";

interface IPluginCardProps {
  plugin: any;
  critical: { plugins: any[] | PluginObject[]; wordpress: any[] | WordpressObject; } | undefined;
}

const PluginCard: React.FunctionComponent<IPluginCardProps> = ({ plugin, critical }) => {
  const [showActions, setShowActions] = useState(false);

  const trigger = useRef<HTMLDivElement>(null);
  useClickAway(trigger, () => setShowActions(false));

  var vulnerabilities = [];
  var vulnerabilitiesFound = false;
  var criticalPluginsLength = (critical?.plugins ? critical.plugins.length : 0);
  for (var i = 0; i < criticalPluginsLength && !vulnerabilitiesFound; i++) {
    if (critical?.plugins[i].slug == plugin.plugin_info.slug) {
      vulnerabilities = critical?.plugins[i].vulnerabilities;
      vulnerabilitiesFound = true;
    }
  }

  return (
    <>
      <div className="c-table-row__content">
        <p className={"c-table-row__title " + (vulnerabilitiesFound ? "c-table-row__title-red-dot" : "")} style={{ width: "50%" }}>{plugin.plugin_info.name}</p>
        <p className={"c-table-row__title " + (vulnerabilitiesFound ? "c-table-row__title-red" : "")} style={{ width: "25%" }}>{plugin.plugin_current_version}</p>
        <p className={"c-table-row__title " + (vulnerabilitiesFound ? "c-table-row__title-red" : "")} style={{ width: "25%" }}>{plugin.plugin_new_version}</p>

        {vulnerabilitiesFound ?
          <div
            onClick={(event) => setShowActions(!showActions)}
            className="c-table-row__icon"
          >
            {showActions
              ? <IconLess className="o-svg-icon" />
              : <IconMore className="o-svg-icon" />
            }
          </div>
          : ""
        }

      </div>

      {vulnerabilitiesFound
        ?
        <div className={"c-table-row__additional-container " + (!showActions ? "c-table__hidden" : "")}>
          {vulnerabilities.map((vulnerability: any) => (
            <div
              className="c-table-row__additional-content"
              key={vulnerability.id}>
              <div className="c-table-row__title" style={{ width: "70%" }}>{vulnerability["title"]}</div>
              <div className="c-table-row__title" style={{ width: "30%" }}>{vulnerability["vuln_type"]}</div>

              {vulnerability["references"]["url"]
                ? <Link
                  className="c-table-row__icon"
                  to={{ pathname: vulnerability["references"]["url"][0] }}
                  target="_blank">
                  <IconShare className="o-svg-icon" />
                </Link>
                : ""}
            </div>
          ))}
        </div>
        : ""
      }
    </>
  );
};

export default PluginCard;
