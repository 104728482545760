import * as React from "react";

import { UserObject } from "api/endpoints/users";
import { Link, useLocation } from "react-router-dom";
import * as quartzite from "quartzite";

interface IUserCardContentProps {
  user: UserObject;
}

const UserCardContent: React.FunctionComponent<IUserCardContentProps> = ({ user }) => {
  let location = useLocation();
  let editUserLink = location.pathname + `/${user.id}/edit`;

  function formatDate(input: string) {
    const date = new Date(input);
    return quartzite.dateString(date);
  }

  return (
    <div className="c-card__content">
      <p className="c-card__title">
        <Link to={editUserLink}>
          {user.first_name} {user.last_name}
        </Link>
      </p>
      <div className="c-card__info">
        <p>
          User role: <strong>{user.role}</strong>
        </p>
        {user.last_seen ? (
          <p>
            Last seen: <strong>{formatDate(user.last_seen)}</strong>
          </p>
        ) : (
          <p>Last seen:</p>
        )}
      </div>
    </div>
  );
};

export default UserCardContent;
