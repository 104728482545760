import * as React from "react";

import api from "api";
import { CertificateObject, CertificateObjectSchema } from "api/endpoints/certificates";
import { ClientObject } from "api/endpoints/clients";
import { SiteObject } from "api/endpoints/sites";
import { useContext, useEffect, useState } from "react";
import { PageContext } from "components/layout/page-context";
import Notification from "components/notification";
import { useParams, useHistory } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import formatDate from "utils/date";
import DeleteConfirmationAlert, {
  DeleteConfirmationAlertType,
} from "components/form/delete-confirmation";
import { debug } from "utils/debug";
import { useStore } from "store";
import useRequest from 'api/use-request';

// Icons
import { ReactComponent as IconPen } from "assets/svg/pen.svg";
import { ReactComponent as IconSync } from "assets/svg/refresh.svg";
import { ReactComponent as IconTrash } from "assets/svg/trash.svg";
import { ReactComponent as IconCritical } from "assets/svg/critical.svg";
import { ReactComponent as IconAttention } from "assets/svg/attention.svg";
import { ReactComponent as IconThumbUp } from "assets/svg/thumb-up.svg";
import { ReactComponent as IconSSL } from "assets/svg/ssl.svg";
import { ReactComponent as IconLock } from "assets/svg/money-lock.svg";
import { domain } from "process";


const CertificateDetailsPage: React.FunctionComponent = () => {
  let user = useRequest(api.users.getCurrentUser());

  const page = useContext(PageContext);
  const { refreshs } = useStore();

  const [certificateErrors, setCertificateErrors] = useState();
  const [certificateWarnings, setCertificateWarnings] = useState();
  const [showActions, setShowActions] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [hostname, setHostname] = useState("Loading...");
  const [isActive, setIsActive] = useState<boolean | null>(null);
  const [expirationDays, setExpirationDays] = useState<number | null>(null);
  const [expirationDate, setExpirationDate] = useState("Loading...");
  const [updatedDate, setUpdatedDate] = useState("Loading...");
  const [issuer, setIssuer] = useState("Loading...");
  const [signature, setSignature] = useState("Loading...");
  const [clientName, setClientName] = useState("Loading...");
  const [siteDomain, setSiteDomain] = useState("Loading...");
  const [isSyncing, setIsSyncing] = useState(false);
  const [errorSyncing, setErrorSyncing] = useState(false);

  let history = useHistory();
  let { certificateId } = useParams() as {
    certificateId: string;
  };

  let location = useLocation();
  let editLink = location.pathname + "/edit";

  const loadCertificate = async (certificateId: string) => {
    let certificate = await api.certificates.getCertificate({ id: certificateId }).fetch();
    if (certificate) {
      setHostname(certificate.hostname);
      setUpdatedDate(formatDate(certificate.updated_at!));
      setIssuer(certificate.issuer);
      setSignature(certificate.signature);
      setIsActive(certificate.active);
      setExpirationDays(certificate.days_to_expiration);
      setExpirationDate(formatDate(certificate.expiration_at));

      loadClient(certificate.client_id);
      if (certificate.site_id != "-") {
        loadSite(certificate.site_id);
      }
    }
  };

  const loadClient = async (clientId: string) => {
    let client = await api.clients.getClient({ id: clientId }).fetch();
    if (client) {
      setClientName(client.name);
    }
  };

  const loadSite = async (siteId: string) => {
    let site = await api.sites.getSite({ id: siteId }).fetch();
    if (site) {
      setSiteDomain(site.domain);
    }
  };

  useEffect(() => {
    page.setTitle("Certificate Details");
    page.setBackLink("/certificates");
    page.setShowsClientSwitcher(false);
    loadCertificate(certificateId);
  }, []);

  useEffect(() => {
    if (refreshs.certificates) {
      loadCertificate(certificateId);
      refreshs.setCertificatesRefresh(false);
    }
  }, [refreshs.certificates])

  useEffect(() => {
    if (isSyncing) {
      setIsSyncing(false);
    }
  }, [updatedDate])

  function deleteCertificate() {
    let update = api.certificates.deleteCertificate({ id: certificateId }).fetch();
    update
      .then((response) => {
        setIsDeleting(false);
        history.push("/certificates");
      })
      .catch((error) => {
        setIsDeleting(false);
        debug(error);
      });
  }

  function sendMockJiraTask() {
    let update = api.certificates.mockCertificateIssue().fetch();
    update
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        debug(error);
      });
  }

  function syncCertificate() {
    setIsSyncing(true);
    setErrorSyncing(false);

    var userId = (user.data ? user.data.id : "");
    let update = api.certificates.syncCertificate({ certificate_id: certificateId, user_id: userId }).fetch();
    update
      .then((response) => {
        refreshs.setCertificatesRefresh(true);
      })
      .catch((error) => {
        setIsSyncing(false);
        setErrorSyncing(true);
        debug(error);
      });
  }

  return (
    <section className="c-block c-block--spacing-b-small">
      <div className="o-container-fluid o-container--full">

        <div className="p-subheader">
          <p className="c-page-subtitle c-title--small">Certificate Monitoring</p>

          <div className="p-actions">
            <div className="o-col o-col--row p-action--padding-right">
              <div className="c-actions">
                <p className="c-caption">Last Update:</p>
                <p className="c-data">{updatedDate}</p>
              </div>
            </div>
            <div className="p-action-link" onClick={() => {
              syncCertificate();
            }}>
              <IconSync className="o-svg-icon" />
              <p className="p-action-name">Sync</p>
            </div>
            <Link className="p-action-link" to={editLink}>
              <IconPen className="o-svg-icon" />
              <p className="p-action-name">Edit</p>
            </Link>
            <div className="p-action-link" onClick={(event) => {
              event.preventDefault();
              setIsDeleting(true);
            }}>
              <IconTrash className="o-svg-icon" />
              <p className="p-action-name">Delete</p>
            </div>
            <div className="p-action-link" onClick={(event) => {
              event.preventDefault();
              sendMockJiraTask();
            }}>
              <p className="p-action-name">Create Jira Task</p>
            </div>
          </div>
        </div>

        <div className="c-page-title">
          <h2 className="c-title--medium">{hostname != "-" ? hostname : siteDomain}</h2>
        </div>

        {certificateErrors && (
          <Notification type={"error"} showsCloseAction>
            <p>
              <strong>Some critical updates are needed!</strong>
            </p>
            <p onClick={() => window.location.reload()}>Click here to review them.</p>
          </Notification>
        )}
        {certificateWarnings && (
          <Notification type={"warning"} showsCloseAction>
            <p>
              <strong>Some certificates are unreachable</strong>
            </p>
            <p onClick={() => window.location.reload()}>Click here to review them.</p>
          </Notification>
        )}
        {isSyncing && (
          <Notification type={"info"}>
            <p>
              <strong>Syncing certificate data...</strong>
            </p>
          </Notification>
        )}
        {errorSyncing && (
          <Notification type={"error"}>
            <p>
              <strong>Error syncing certificate data, try again later.</strong>
            </p>
          </Notification>
        )}

        <div className="o-container--sm">
          <div className="o-row o-row--no-margin">
            <div className="o-col">
              <div className="c-actions">
                <p className="c-caption">URL:</p>
                <p className="c-data">{hostname != "-" ? hostname : siteDomain}</p>
              </div>
            </div>
            <div className="o-col">
              <div className="c-actions">
                <p className="c-caption">Client:</p>
                <p className="c-data">{clientName}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="details-content">
          <div className="c-details-container">
            <div className="c-detail c-detail--lg">
              <IconSSL className="c-detail-icon" />
              <div className="c-detail-content">
                <div className="c-detail-content-header">
                  Site Status
                </div>
                {isActive === null ? "Loading..."
                  : (isActive == false
                    ? <div className="c-detail-content-text yellow-text">
                      <strong>
                        Unreachable
                      </strong>
                    </div>
                    : <div className="c-detail-content-text">
                      <strong>Active</strong>
                    </div>)}
              </div>

              {isActive === null || isActive == true ?
                <IconThumbUp className="c-detail-status" />
                : <IconAttention className="c-detail-status" />
              }
            </div>

            <div className="c-detail-notes c-detail--sm">
              <div className="c-detail-note">
                <div className="c-detail-note-title">Issuer:</div>
                <div className="c-detail-note-data">{issuer}</div>
              </div>
              <div className="c-detail-note">
                <div className="c-detail-note-title">Signature:</div>
                <div className="c-detail-note-data">{signature}</div>
              </div>
            </div>
          </div>

          <div className="c-details-container">
            <div className={"c-detail c-detail--lg " + (expirationDays !== null && expirationDays < 0 ? "c-detail--red-background" : "")}>
              <IconLock className="c-detail-icon" />
              <div className="c-detail-content">
                <div className="c-detail-content-header">
                  SSL Expiration
                </div>

                {expirationDays === null ? "Loading..."
                  : (expirationDays < 0 ?
                    <div className="c-detail-content-text red-text">
                      <p><strong>Expired</strong> on {expirationDate}</p></div>
                    : <div className="c-detail-content-text">
                      <p><strong>Valid</strong> until {expirationDate}</p></div>)
                }

              </div>
              {expirationDays === null ? "Loading..."
                : (expirationDays < 0 ?
                  <IconCritical className="c-detail-status" />
                  : expirationDays > 7
                    ? <div className="c-detail-status-text green-text">
                      <p><strong>{expirationDays}</strong><br />days left</p>
                    </div>
                    : <div className="c-detail-status-text yellow-text">
                      <p><strong>{expirationDays}</strong><br />days left</p>
                    </div>)
              }
            </div>
          </div>
        </div>

        <DeleteConfirmationAlert
          onDelete={deleteCertificate}
          onCancel={() => {
            setIsDeleting(false);
          }}
          resource_label={hostname != "-" ? hostname : siteDomain}
          show={isDeleting}
          type={DeleteConfirmationAlertType.Card}
        />
      </div>
    </section>
  );
};

export default CertificateDetailsPage;
