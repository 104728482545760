import * as React from "react";

import { Switch, Route, useParams } from "react-router-dom";
import { UserDetails } from "components/cards/user-edit";
import { useStore } from "store";

export interface IUserEditModalProps {}

const UserEditModal: React.FunctionComponent<IUserEditModalProps> = () => {
  const { refreshs } = useStore();
  let { clientId } = useParams() as {
    clientId: string | undefined;
    userId: string | undefined;
  };

  function onUpdate() {
    refreshs.setUsersRefresh(true);
  }

  return (
    <Switch>
      <Route path={`/users/:userId/edit`}>
        <UserDetails isNew={false} nextLocation={`/users`} onUpdate={onUpdate} isProfile={false} />
      </Route>
      <Route path={`/users/new`}>
        <UserDetails isNew={true} nextLocation={`/users`} onUpdate={onUpdate} isProfile={false} />
      </Route>
    </Switch>
  );
};

export default UserEditModal;
