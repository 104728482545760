import * as React from "react";

// Components
import Listing from "components/cards/listing";
import {
  listingContext,
  ListingSortOption,
  ListingType,
  SortDirection,
} from "components/cards/listing-context";

import api from "api";
import useRequest from "api/use-request";
import { useState, Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useStore } from "store";
import ListingCard from "./listing-card";
import CertificateCardContent from "./certificate-card-content";
import AddNewBlock from "./add-new";

export interface ICertificatesListingProps {
  showAllCertificates: boolean;
  clientId: string;
}

const CertificatesListing: React.FunctionComponent<ICertificatesListingProps> = ({ showAllCertificates, clientId }) => {
  let location = useLocation();
  const { refreshs, errors } = useStore();
  let searchArgs = queryString.parse(location.search, { parseNumbers: true });
  if (searchArgs.limit === undefined) {
    searchArgs.limit = showAllCertificates ? 48 : 12;
  }

  let [selection, setSelection] = useState<string[]>([]);

  let certificates = useRequest(api.certificates.getCertificates(searchArgs));

  let sort_options: Array<ListingSortOption> = [
    { key: "expiration_at", title: "Expiration At - Asc", direction: SortDirection.Ascending },
    { key: "expiration_at", title: "Expiration At - Desc", direction: SortDirection.Descending },
  ];

  function deleteCertificates(ids: string[]) {
    let updates: Promise<unknown>[] = [];
    ids.forEach((id) => {
      updates.push(api.certificates.deleteCertificate({ id }).fetch());
    });
    Promise.all(updates).finally(() => {
      certificates.mutate();
      setSelection([]);
    });
  }

  function toggleSelectCertificates(ids: string[]) {
    ids.forEach((id) => {
      const index = selection.indexOf(id);
      if (index > -1) {
        selection.splice(index, 1);
      } else {
        selection.push(id);
      }
    });
    setSelection([...selection]);
  }

  function selectAll(shouldSelect: boolean) {
    if (certificates.data && certificates.data?.data.length > 0) {
      certificates.data?.data.forEach((certificate) => {
        const index = selection.indexOf(certificate.id);
        if (shouldSelect && index < 0) {
          selection.push(certificate.id);
        }
        if (!shouldSelect && index > -1) {
          selection.splice(index, 1);
        }
      });
      setSelection([...selection]);
    }
  }

  useEffect(() => {
    if (refreshs.certificates) {
      certificates.mutate();
      refreshs.setCertificatesRefresh(false);
    }
  }, [refreshs.certificates]);

  return (
    <>
      {certificates.data && certificates.data?.data.length < 1 && !searchArgs.q ? (
        <AddNewBlock
          title="Add new Domain"
          subtitle="You currently don’t have any domain added, click here to start."
        />
      ) : (
        <>
          <listingContext.Provider value={{ ...certificates, selection, sort_options }}>
            <Suspense fallback={<div>Loading...</div>}>
              <Listing
                deleteHandler={deleteCertificates}
                selectionHandler={toggleSelectCertificates}
                selectAllHandler={selectAll}
                defaultListingType={showAllCertificates ? ListingType.list : ListingType.grid}
                defaultLimit={showAllCertificates ? 48 : 12}
                shouldShowSearch={true}
                settingsKey={showAllCertificates ? "AllCertificates" : "Certificates"}
              >
                {certificates.data?.data.map((certificate) => (
                  <ListingCard
                    deleteHandler={deleteCertificates}
                    selectHandler={toggleSelectCertificates}
                    key={certificate.id}
                    itemId={certificate.id}
                    itemLabel={certificate.hostname != "-" ? certificate.hostname : "this certificate"}
                    itemName="Certificate"
                    showView={true}
                  >
                    <CertificateCardContent certificate={certificate} />
                  </ListingCard>
                ))}
              </Listing>
            </Suspense>
          </listingContext.Provider>
        </>
      )}
    </>
  );
};

export default CertificatesListing;
