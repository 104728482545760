import * as React from "react";

import api from "api";
import { ClientObject } from "api/endpoints/clients";
import { SiteObject } from "api/endpoints/sites";
import { CertificateObject } from "api/endpoints/certificates";

import { useContext, useEffect, useState, Suspense } from "react";
import { PageContext } from "components/layout/page-context";
import Notification from "components/notification";
import { useParams, useHistory } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import formatDate from "utils/date";
import DeleteConfirmationAlert, {
  DeleteConfirmationAlertType,
} from "components/form/delete-confirmation";
import { debug } from "utils/debug";
import { useStore } from "store";
import useRequest from 'api/use-request';

// Components
import Table from "components/cards/table";
import TableCard from "components/cards/table-card";
import TableSitesCard from "components/cards/table-sites-card";
import TableCertificatesCard from "components/cards/table-certificates-card";

// Icons
import { ReactComponent as IconPen } from "assets/svg/pen.svg";
import { ReactComponent as IconTrash } from "assets/svg/trash.svg";

const ClientDetailsPage: React.FunctionComponent = () => {
  const page = useContext(PageContext);
  const { refreshs } = useStore();

  const [clientErrors, setClientErrors] = useState();
  const [clientWarnings, setClientWarnings] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedTab, setSelectedTab] = useState("sites");

  const [client, setClient] = useState({} as ClientObject);

  const [clientName, setClientName] = useState("Loading...");
  const [url, setUrl] = useState("Loading...");
  const [project, setProject] = useState("Loading...");

  const [sites, setSites] = useState<SiteObject[]>([]);
  const [certificates, setCertificates] = useState<CertificateObject[]>([]);

  let history = useHistory();
  let { clientId } = useParams() as {
    clientId: string;
  };

  let location = useLocation();
  let editLink = location.pathname + "/edit";

  const loadClient = async (clientId: string) => {
    let client = await api.clients.getClient({ id: clientId }).fetch();
    if (client) {
      setClientName(client.name);
      setUrl(client.url);
      setProject(client.project_id ? client.project_id : "Not Defined");
    }
  };

  const loadSites = async (clientId: string) => {
    let sites = await api.sites.getSitesByClientId(clientId).fetch();
    if (sites) {
      setSites(sites);
    }
  };

  const loadCertificates = async (clientId: string) => {
    let certificates = await api.certificates.getCertificatesByClientId(clientId).fetch();
    if (certificates) {
      setCertificates(certificates);
    }
  };

  useEffect(() => {
    page.setTitle("Client Details");
    page.setBackLink("/clients");
    page.setShowsClientSwitcher(false);
    loadClient(clientId);
    loadSites(clientId);
    loadCertificates(clientId);
  }, []);

  useEffect(() => {
    if (refreshs.clients) {
      loadClient(clientId);
      refreshs.setClientsRefresh(false);
    }
  }, [refreshs.clients])

  function deleteClient() {
    let update = api.clients.deleteClient({ id: clientId }).fetch();
    update
      .then((response) => {
        setIsDeleting(false);
        history.push("/clients");
      })
      .catch((error) => {
        setIsDeleting(false);
        debug(error);
      });
  }

  let sites_columns = [
    { id: "name", name: "Name", size: "20%" },
    { id: "domain", name: "Domain", size: "20%" },
    { id: "project_id", name: "Jira Project", size: "20%" },
    { id: "api_key", name: "API key", size: "40%" }];

  let sites_table_limit = 10;

  let certificates_columns = [
    { id: "hostname", name: "Hostname", size: "20%" },
    { id: "days_to_expiration", name: "Expires in", size: "20%" },
    { id: "issuer", name: "Issuer", size: "20%" },
    { id: "signature", name: "Signature", size: "20%" },
    { id: "synced_at", name: "Last Synced at", size: "20%" }];

  let certificates_table_limit = 10;

  return (
    <section className="c-block c-block--spacing-b-small">
      <div className="o-container-fluid o-container--full">

        <div className="p-subheader">
          <p className="c-page-subtitle c-title--small">Clients</p>

          <div className="p-actions">
            <div className="p-action-container">
              <Link className="p-action-link p-action-link__no-border" to={editLink}>
                <IconPen className="o-svg-icon" />
                <p className="p-action-name">Edit</p>
              </Link>
            </div>
            <div className="p-action-link" onClick={(event) => {
              event.preventDefault();
              setIsDeleting(true);
            }}>
              <IconTrash className="o-svg-icon" />
              <p className="p-action-name">Delete</p>
            </div>
          </div>
        </div>

        <div className="c-page-title">
          <h2 className="c-title--medium">{clientName}</h2>
        </div>

        {clientErrors && (
          <Notification type={"error"} showsCloseAction>
            <p>
              <strong>Some critical updates are needed!</strong>
            </p>
            <p onClick={() => window.location.reload()}>Click here to review them.</p>
          </Notification>
        )}
        {clientWarnings && (
          <Notification type={"warning"} showsCloseAction>
            <p>
              <strong>Some sites are unreachable</strong>
            </p>
            <p onClick={() => window.location.reload()}>Click here to review them.</p>
          </Notification>
        )}

        <div className="o-container--sm">
          <div className="o-row o-row--no-margin">
            <div className="o-col">
              <div className="c-actions">
                <p className="c-caption">Website URL:</p>
                <p className="c-data">{url}</p>
              </div>
            </div>
            <div className="o-col">
              <div className="c-actions">
                <p className="c-caption">Jira Project:</p>
                <p className="c-data">{project}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="c-page-tabs">
          <div className={"c-page-tab " + (selectedTab == "sites" ? "c-page-selected-tab" : "")} onClick={(event) => {
              setSelectedTab("sites");
            }}>Sites</div>
          <div className={"c-page-tab " + (selectedTab == "certificates" ? "c-page-selected-tab" : "")} onClick={(event) => {
              setSelectedTab("certificates");
            }}>Certificates</div>
        </div>

        <div className="details-content">
          <div className={"c-details-container c-details-container--self-start " + (selectedTab == "sites" ? "" : "c-table__hidden")}>
            <div className="c-table c-detail--lg">
              <Suspense fallback={<div>Loading...</div>}>
                <Table
                  //selectionHandler={openPluginPopup}
                  columns={sites_columns}
                  defaultLimit={sites_table_limit}
                  data={sites ? sites : []}
                  setData={setSites}
                >
                  {sites?.map((site, index) => (
                    <TableCard
                      key={site.id}
                      itemId={site.id}
                      index={index}
                    >
                      <TableSitesCard site={site} />
                    </TableCard>
                  ))}
                </Table>
              </Suspense>
            </div>
          </div>

          <div className={"c-details-container c-details-container--self-start " + (selectedTab == "certificates" ? "" : "c-table__hidden")}>
            <div className="c-table c-detail--lg">
              <Suspense fallback={<div>Loading...</div>}>
                <Table
                  //selectionHandler={openPluginPopup}
                  columns={certificates_columns}
                  defaultLimit={certificates_table_limit}
                  data={certificates ? certificates : []}
                  setData={setCertificates}
                >
                  {certificates?.map((certificate, index) => (
                    <TableCard
                      key={certificate.id}
                      itemId={certificate.id}
                      index={index}
                    >
                      <TableCertificatesCard certificate={certificate} />
                    </TableCard>
                  ))}
                </Table>
              </Suspense>
            </div>
          </div>
        </div>

        <DeleteConfirmationAlert
          onDelete={deleteClient}
          onCancel={() => {
            setIsDeleting(false);
          }}
          resource_label={clientName}
          show={isDeleting}
          type={DeleteConfirmationAlertType.Card}
        />

      </div>
    </section>
  );
};

export default ClientDetailsPage;
