import * as React from "react";
import NotificationsMenu from "./notifications-menu";
import UserMenu from "./user-menu";

// Icon
import { ReactComponent as IconArrow } from "assets/svg/arrow.svg";
import { useContext } from "react";
import { PageContext } from "./page-context";
import { Link } from "react-router-dom";
import { AppErrors } from "./app-errors";
import SettingsMenu from "./settings-menu";
import { AppNotifications } from "./app-notifications";
import PageTitle from "./page-title";

interface IHeaderProps {
  ref: React.Ref<HTMLElement>;
}

const Header: React.ForwardRefExoticComponent<IHeaderProps> = React.forwardRef((props, ref) => {
  const page = useContext(PageContext);
  let logoUrl = page.usesDarkMode ? page.logoDarkURL : page.logoURL;

  return (
    <header id="site-header" className="c-site-header" ref={ref}>
      <div className="o-container-fluid o-container--full">
        <div className="c-site-header__wrapper">
          <div className="c-site-header__main">
            <AppErrors />
            <AppNotifications />
            <div className="c-site-header__headline">
              {page.backLink && (
                <p>
                  <Link to={page.backLink} className="c-link-cta-basic">
                    <IconArrow className="o-svg-icon o-svg-left" />
                    <span>Back</span>
                  </Link>
                </p>
              )}
              <div className="c-site-logo"></div>
            </div>
          </div>

          <div className="c-site-header__utility">
            <SettingsMenu />
            <NotificationsMenu />
            <UserMenu />
          </div>
        </div>
      </div>
    </header>
  );
});

export default Header;
