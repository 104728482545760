import * as React from "react";

// Components
import Listing from "components/cards/listing";
import {
  listingContext,
  ListingSortOption,
  ListingType,
  SortDirection,
} from "components/cards/listing-context";

import api from "api";
import useRequest from "api/use-request";
import { useState, Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useStore } from "store";
import ListingCard from "./listing-card";
import SiteCardContent from "./site-card-content";
import AddNewBlock from "./add-new";

export interface ISitesListingProps {
  showAllSites: boolean;
  clientId: string;
}

const SitesListing: React.FunctionComponent<ISitesListingProps> = ({ showAllSites, clientId }) => {
  let location = useLocation();
  const { refreshs, errors } = useStore();
  let searchArgs = queryString.parse(location.search, { parseNumbers: true });
  if (searchArgs.client_id === undefined) {
    searchArgs.client_id = clientId;
  }
  if (searchArgs.limit === undefined) {
    searchArgs.limit = showAllSites ? 48 : 12;
  }

  let [selection, setSelection] = useState<string[]>([]);

  let sites = useRequest(api.sites.getSites(searchArgs));

  let sort_options: Array<ListingSortOption> = [
    { key: "name", title: "Name, Ascending", direction: SortDirection.Ascending },
    { key: "name", title: "Name, Descending", direction: SortDirection.Descending },
    { key: "domain", title: "Domain, Ascending", direction: SortDirection.Ascending },
    { key: "domain", title: "Domain, Descending", direction: SortDirection.Descending },
    { key: "created_at", title: "Created At, Ascending", direction: SortDirection.Ascending },
    { key: "created_at", title: "Created At, Descending", direction: SortDirection.Descending },
  ];

  function deleteSites(ids: string[]) {
    let updates: Promise<unknown>[] = [];
    ids.forEach((id) => {
      updates.push(api.sites.deleteSite({ id }).fetch());
    });
    Promise.all(updates).finally(() => {
      sites.mutate();
      setSelection([]);
    });
  }

  function toggleSelectSites(ids: string[]) {
    ids.forEach((id) => {
      const index = selection.indexOf(id);
      if (index > -1) {
        selection.splice(index, 1);
      } else {
        selection.push(id);
      }
    });
    setSelection([...selection]);
  }

  function selectAll(shouldSelect: boolean) {
    if (sites.data && sites.data?.data.length > 0) {
      sites.data?.data.forEach((site) => {
        const index = selection.indexOf(site.id);
        if (shouldSelect && index < 0) {
          selection.push(site.id);
        }
        if (!shouldSelect && index > -1) {
          selection.splice(index, 1);
        }
      });
      setSelection([...selection]);
    }
  }

  useEffect(() => {
    if (refreshs.sites) {
      sites.mutate();
      refreshs.setSitesRefresh(false);
    }
  }, [refreshs.sites]);

  return (
    <>
      {sites.data && sites.data?.data.length < 1 && !searchArgs.q ? (
        <AddNewBlock
          title="Add new site"
          subtitle="You currently don’t have any sites added, click here to start."
        />
      ) : (
        <>
          <listingContext.Provider value={{ ...sites, selection, sort_options }}>
            <Suspense fallback={<div>Loading...</div>}>
              <Listing
                deleteHandler={deleteSites}
                selectionHandler={toggleSelectSites}
                selectAllHandler={selectAll}
                defaultListingType={showAllSites ? ListingType.list : ListingType.grid}
                defaultLimit={showAllSites ? 48 : 12}
                shouldShowSearch={true}
                compact
                settingsKey={showAllSites ? "AllSites" : "Sites"}
              >
                {sites.data?.data.map((site) => (
                  <ListingCard
                    deleteHandler={deleteSites}
                    selectHandler={toggleSelectSites}
                    key={site.id}
                    itemId={site.id}
                    itemLabel={site.name}
                    itemName="Site"
                    showView={true}
                  >
                    <SiteCardContent site={site} />
                  </ListingCard>
                ))}
              </Listing>
            </Suspense>
          </listingContext.Provider>
        </>
      )}
    </>
  );
};

export default SitesListing;
