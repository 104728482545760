import * as React from "react";

import { CertificateObject } from "api/endpoints/certificates";
import { Link, useLocation } from "react-router-dom";
import * as quartzite from "quartzite";
import formatDate from "utils/date";
import { useEffect, useState } from "react";
import api from "api";
import {
  SiteObject,
} from "api/endpoints/sites";

interface ICertificateCardContentProps {
  certificate: CertificateObject;
}

const CertificateCardContent: React.FunctionComponent<ICertificateCardContentProps> = ({ certificate }) => {
  let location = useLocation();
  let editCertificateLink = location.pathname + `/${certificate.id}/edit`;

  let [certificateTitle, setCertificateTitle] = useState("Loading...");

  const initSite = () => {
    loadCertificateTitle();
  };

  useEffect(initSite, []);

  async function loadCertificateTitle() {
    if (certificate.hostname !== "-") {
      setCertificateTitle(certificate.hostname);
    } else {
      const site = await api.sites.getSite({ id: certificate.site_id }).fetch();
      if (site) {
        setCertificateTitle(site.domain);
      }
    }
  }

  return (
    <div className="c-card__content">
      <p className="c-card__title">
        <Link to={editCertificateLink}>{certificateTitle}</Link>
      </p>
      <div className="c-card__info">
        {!certificate.active
          ? <p style={{ color: '#e15f48' }}><strong>Not active, please check data</strong></p>
          : (!certificate.valid
            ? <p style={{ color: '#e15f48' }}><strong>Not valid, please check data</strong></p>
            : (certificate.days_to_expiration < 0 ?
              <p style={{ color: '#e15f48' }}><strong>SSL expired: {formatDate(certificate.expiration_at)}</strong></p>
              : certificate.days_to_expiration > 7
                ? <p>SSL Expiration: <strong>{certificate.days_to_expiration} days left</strong></p>
                : <p>SSL Expiration: <strong style={{ color: '#e8a530' }}>{certificate.days_to_expiration} days left</strong></p>))
        }

        {certificate.updated_at ? (
          <p>Last Update: {" "} {formatDate(certificate.updated_at)}</p>
        ) : (
          <p></p>
        )}

      </div>
    </div>
  );
};

export default CertificateCardContent;
