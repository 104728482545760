import * as React from "react";

import { Switch, Route, useParams } from "react-router-dom";
import { CertificateDetails } from "components/cards/certificate-edit";
import { useStore } from "store";

export interface ICertificateEditModalProps {}

const CertificateEditModal: React.FunctionComponent<ICertificateEditModalProps> = () => {
  const { refreshs } = useStore();
  let { clientId } = useParams() as {
    clientId: string | undefined;
    certificateId: string | undefined;
  };

  function onUpdate() {
    refreshs.setCertificatesRefresh(true);
  }

  return (
    <Switch>
      <Route path={`/certificates/:certificateId/edit`}>
        <CertificateDetails isNew={false} nextLocation={`/certificates`} onUpdate={onUpdate} />
      </Route>
      <Route path={`/certificates/new`}>
        <CertificateDetails isNew={true} nextLocation={`/certificates`} onUpdate={onUpdate} />
      </Route>
      <Route path={`/certificates/:certificateId/view/edit`}>
        <CertificateDetails isNew={false} nextLocation={`/certificates/:certificateId/view`} onUpdate={onUpdate} />
      </Route>
    </Switch>
  );
};

export default CertificateEditModal;
