import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { LocationState } from 'routes';
import { useStore } from 'store';

export interface IAuthenticatedRouteProps extends RouteProps{
}

export function AuthenticatedRoute({ children, ...rest }: IAuthenticatedRouteProps) {
  const store = useStore();

  function redirectToAfterLogin(locationPath: string): LocationState {
    // Avoid redirection back to these paths
    const invalidPaths = ['/login', '/401', '/404', '/users']
    if (invalidPaths.includes(locationPath)) {
      return { from: undefined };
    } else {
      return {
        from: {
          pathname: locationPath
        }
      };
    }
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (store.isLoggedIn) {
          return (children);
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: redirectToAfterLogin(location.pathname) }
              }}
            />
          )
        }
      }}
    />
  );
}

