import * as React from "react";

// Icons
import { ReactComponent as HamburgerIcon } from "assets/svg/list.svg";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import useRequest from "api/use-request";
import api from "api";
import useClickAway from "hooks/use-click-away";

interface IMainNavProps {}

const MainNav: React.FunctionComponent<IMainNavProps> = (props) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [hasMenu, setHasMenu] = useState(false);
  const user = useRequest(api.users.getCurrentUser());

  const container = useRef<HTMLDivElement>(null);
  useClickAway(container, () => setPopupOpen(false));

  useEffect(() => {
    setHasMenu(true);
  }, [user.data]);

  if (!hasMenu) {
    return null;
  }

  return (
    <nav ref={container} className={classNames(["c-site-nav", { "has-popup-open": popupOpen }])}>
      <div className="c-site-nav__header">
        <div
          className={classNames([
            "c-site-nav-toggle",
            "js-offcanvas-toggle",
            { "is-active": popupOpen },
          ])}
          data-offcanvas="navigation"
          onClick={(event) => {
            event.preventDefault();
            setPopupOpen(!popupOpen);
          }}
        >
          <HamburgerIcon className="0-icon o-svg-icon" />
        </div>
      </div>
      {hasMenu && (
        <div className="c-popup">
          <div className="c-popup__header">
            <p className="c-popup__title">Close menu</p>
            <div className="c-popup__toggle" onClick={() => setPopupOpen(false)}>
              <CloseIcon className="0-icon o-svg-icon" />
            </div>
          </div>
          <div className="c-popup__body">
            <ul className="c-popup__list c-popup__list--small">
              <li>
                <Link onClick={() => setPopupOpen(false)} to="/sites">
                  <span>Security Monitoring</span>
                </Link>
              </li>
              <li>
                <Link onClick={() => setPopupOpen(false)} to="/certificates">
                  <span>Certificates Monitoring</span>
                </Link>
              </li>
              <li>
                <Link onClick={() => setPopupOpen(false)} to="/clients">
                  <span>Clients</span>
                </Link>
              </li>
              <li>
                <Link onClick={() => setPopupOpen(false)} to="/users">
                  <span>Users</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
};

export default MainNav;
