import HttpClient from "api/http-client";
import * as z from "zod";
import { GetListingRequestSchema, LinksSchema, MetaSchema } from "api/endpoints/common";

const dateRegex =
  /^(?<fullyear>\d{4})-(?<month>0[1-9]|1[0-2])-(?<mday>0[1-9]|[12][0-9]|3[01])T(?<hour>[01][0-9]|2[0-3]):(?<minute>[0-5][0-9]):(?<second>[0-5][0-9]|60)(?<secfrac>\.[0-9]+)?(Z|(\+|-)(?<offset_hour>[01][0-9]|2[0-3]):(?<offset_minute>[0-5][0-9]))$/i;

export const ClientObjectSchema = z.object({
  id: z.string(),
  name: z.string().nonempty(),
  url: z.string().nonempty(),
  project_id: z.string().optional(),
  created_at: z.string().regex(dateRegex).optional(),
  updated_at: z.string().regex(dateRegex).optional(),
});

export const ClientFormSchema = z.object({
  id: z.string(),
  name: z.string().nonempty(),
  url: z.string().nonempty(),
  project_id: z.string().optional(),
});

export const CreateClientResponseSchema = ClientObjectSchema;

export const GetClientsResponseSchema = z.object({
  data: z.array(ClientObjectSchema),
  links: LinksSchema,
  meta: MetaSchema,
});

export const GetClientsRequestSchema = GetListingRequestSchema;

export const ClientObjectIdSchema = z.object({
  id: z.string(),
});

export type ClientSelect = { id: string; name: string };
export type ClientObject = z.infer<typeof ClientObjectSchema>;
export type ClientFormObject = z.infer<typeof ClientFormSchema>;
export type GetClientsRequest = z.infer<typeof GetClientsRequestSchema>;
export type GetClientsResponse = z.infer<typeof GetClientsResponseSchema>;
export type ClientObjectId = z.infer<typeof ClientObjectIdSchema>;

export function getClients(data: GetClientsRequest) {
  return new HttpClient().request<GetClientsResponse>({
    method: "get",
    url: "/clients",
    validator: GetClientsResponseSchema,
    params: data,
  });
}

export function newClient(data: Omit<ClientFormObject, "id">) {
  return new HttpClient().request<ClientObject>({
    method: "post",
    url: "/clients",
    validator: ClientObjectSchema,
    data,
  });
}

export function getClient(data: ClientObjectId) {
  return new HttpClient().request<ClientObject>({
    method: "get",
    url: `/clients/${data.id}`,
    validator: ClientObjectSchema,
  });
}

export function deleteClient(data: ClientObjectId) {
  return new HttpClient().request({
    method: "delete",
    url: `/clients/${data.id}`,
    data,
  });
}

export function updateClient(data: ClientFormObject, client_id: string) {
  return new HttpClient().request<ClientObject>({
    method: "put",
    url: `/clients/${client_id}`,
    data,
  });
}

export function selectClientId(clients?: ClientObject[]): ClientSelect[] {
  let clientsList: ClientSelect[] = [];

  clients?.forEach((client) => {
    let userSelect = {
      id: client.id,
      name: client.name
    }
    clientsList.push(userSelect);
  });
  return clientsList;
}
