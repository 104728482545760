import * as React from "react";
import { ReactChild, useContext, useState } from "react";
import classNames from "classnames";

import Skeleton from "react-loading-skeleton";
import TableFooter from "components/cards/table-footer";

interface ITableProps {
  children?: ReactChild[];
  //selectionHandler?: (selection: any[]) => void;
  defaultLimit: number;
  columns: any[];
  data: any[];
  setData: React.Dispatch<React.SetStateAction<any>>;
}

const Table: React.FunctionComponent<ITableProps> = ({
  children,
  //selectionHandler,
  defaultLimit,
  columns,
  data,
  setData
}) => {
  const [sort, setSort] = useState("");
  const [ascending, setAscending] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  function changeSort(key: string) {
    var isAscending = (key == sort && ascending ? false : true);
    setAscending(isAscending);
    setSort(key);

    var sortSplit = key.split(".");

    data = [...data].sort((a, b) => {
      var dataA = a;
      var dataB = b;

      //In case we are trying to access a nested property
      for (var i = 0; i < sortSplit.length; i++) {
        dataA = dataA[sortSplit[i]];
        dataB = dataB[sortSplit[i]];
      }

      var position = dataA < dataB ? -1 : 1
      position = (isAscending ? position : position * -1);
      return position;
    });
    setData(data);
  }

  return (
    <>
      <div className="c-table__header u-justify-between u-items-center">
        {columns?.map((column) => (
          <div className={"c-table__header-title " + ((sort == column.id) ? (ascending ? "c-table__header-ascending" : "c-table__header-descending") : "")}
            key={column.id}
            style={{ width: column.size }}
            onClick={(event) => changeSort(column.id)}
          >
            {column.name}
          </div>
        ))}
      </div>

      <div className="c-table-contents">
        {children
          ? (React.Children.map(children, (child, index) => {
            if (index >= ((currentPage - 1) * defaultLimit) && index < (currentPage * defaultLimit)) {
              return child
            }
          }))
          : <RowSkeleton count={5} />}
      </div>

      <TableFooter
      defaultLimit={defaultLimit}
      data={data}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      />
    </>
  );
};

interface IRowSkeleton {
  count: number;
}

const RowSkeleton = ({ count }: IRowSkeleton) => {
  const rows = [];
  for (let index = 0; index < count; index++) {
    rows[index] = (
      <div className="c-table__item c-table__item--skeleton" key={"card_" + index}>
        <div className="c-card">
          <Skeleton />
        </div>
      </div>
    );
  }
  return <>{rows}</>;
};

export default Table;
