import * as React from "react";

// Components
import ListingCard from "./listing-card";
import Listing from "components/cards/listing";
import {
  listingContext,
  ListingSortOption,
  ListingType,
  SortDirection,
} from "components/cards/listing-context";

import api from "api";
import useRequest from "api/use-request";
import { useState, Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useStore } from "store";
import UserCardContent from "./user-card-content";
import AddNewBlock from "./add-new";

export interface IUsersListingProps {
  showAllUsers: boolean;
  clientId: string;
}

const UsersListing: React.FunctionComponent<IUsersListingProps> = ({ showAllUsers, clientId }) => {
  let location = useLocation();
  const { refreshs } = useStore();
  let searchArgs = queryString.parse(location.search, { parseNumbers: true });
  if (searchArgs.client_id === undefined) {
    searchArgs.client_id = clientId;
  }
  if (searchArgs.limit === undefined) {
    searchArgs.limit = showAllUsers ? 48 : 12;
  }

  let [selection, setSelection] = useState<string[]>([]);

  const currentUser = useRequest(api.users.getCurrentUser());
  let users = useRequest(api.users.getUsers(searchArgs));

  let sort_options: Array<ListingSortOption> = [
    { key: "first_name", title: "First Name, Ascending", direction: SortDirection.Ascending },
    { key: "first_name", title: "First Name, Descending", direction: SortDirection.Descending },
    { key: "created_at", title: "Created At, Ascending", direction: SortDirection.Ascending },
    { key: "created_at", title: "Created At, Descending", direction: SortDirection.Descending },
    { key: "last_seen", title: "Last Seen, Ascending", direction: SortDirection.Ascending },
    { key: "last_seen", title: "Last Seen, Descending", direction: SortDirection.Descending },
    { key: "role", title: "Role, Ascending", direction: SortDirection.Ascending },
    { key: "role", title: "Role, Descending", direction: SortDirection.Descending },
  ];

  function deleteUsers(ids: string[]) {
    let updates: Promise<unknown>[] = [];
    ids.forEach((id) => {
      updates.push(api.users.deleteUser({ id }).fetch());
    });
    Promise.all(updates).finally(() => {
      users.mutate();
      setSelection([]);
    });
  }

  function toggleSelectUsers(ids: string[]) {
    ids.forEach((id) => {
      const index = selection.indexOf(id);
      if (index > -1) {
        selection.splice(index, 1);
      } else {
        selection.push(id);
      }
    });
    setSelection([...selection]);
  }

  function selectAll(shouldSelect: boolean) {
    if (users.data && users.data?.data.length > 0) {
      users.data?.data.forEach((user) => {
        const index = selection.indexOf(user.id);
        if (shouldSelect && index < 0) {
          selection.push(user.id);
        }
        if (!shouldSelect && index > -1) {
          selection.splice(index, 1);
        }
      });
      setSelection([...selection]);
    }
  }

  useEffect(() => {
    if (refreshs.users) {
      users.mutate();
      refreshs.setUsersRefresh(false);
    }
  }, [refreshs.users]);

  return (
    <>
      {users.data && users.data?.data.length < 1 && !searchArgs.q ? (
        <AddNewBlock
          title="Add new user"
          subtitle="You currently don’t have any users added, click here to start."
        />
      ) : (
        <>
          <listingContext.Provider value={{ ...users, selection, sort_options }}>
            <Suspense fallback={<div>Loading...</div>}>
              <Listing
                deleteHandler={deleteUsers}
                selectionHandler={toggleSelectUsers}
                selectAllHandler={selectAll}
                defaultListingType={showAllUsers ? ListingType.list : ListingType.grid}
                defaultLimit={showAllUsers ? 48 : 12}
                shouldShowSearch={true}
                settingsKey={showAllUsers ? "AllUsers" : "Users"}
              >
                {users.data?.data.map((user) => (
                  <ListingCard
                    deleteHandler={deleteUsers}
                    selectHandler={toggleSelectUsers}
                    key={user.id}
                    itemId={user.id}
                    itemLabel={`${user.first_name} ${user.last_name}`}
                    itemName="User"
                    showView={false}
                  >
                    <UserCardContent user={user} />
                  </ListingCard>
                ))}
              </Listing>
            </Suspense>
          </listingContext.Provider>
        </>
      )}
    </>
  );
};

export default UsersListing;
