import * as React from "react";

import CertificatesListing from "components/cards/certificates-listing";

// Icons
import { ReactComponent as IconAdd } from "assets/svg/add.svg";
import { useContext, useEffect, useState } from "react";
import { PageContext } from "components/layout/page-context";
import { Link } from "react-router-dom";
import Notification from "components/notification";

const CertificatesIndexPage: React.FunctionComponent = () => {
  const page = useContext(PageContext);
  const [certificateErrors, setCertificateErrors] = useState();
  const [certificateWarnings, setCertificateWarnings] = useState();

  useEffect(() => {
    page.setTitle("Certificates Monitoring");
    page.setBackLink();
    page.setShowsClientSwitcher(false);
  });

  return (
    <section className="c-block c-block--spacing-b-small">
      <div className="o-container-fluid o-container--full">
        <div className="c-page-title">
          <h2 className="c-title--large">Certificates Monitoring</h2>
          <Link
            to="/certificates/new"
            className="c-button c-button--stroke c-button--secondary c-button--icon u-hidden@md"
          >
            <IconAdd className="o-svg-icon" />
          </Link>
          <Link
            to="/certificates/new"
            className="c-button c-button--stroke c-button--secondary u-hidden u-block@md"
          >
            <IconAdd className="o-svg-icon" />
            <span>Add new Domain</span>
          </Link>
        </div>
        {certificateErrors && (
          <Notification type={"error"} showsCloseAction>
            <p>
              <strong>Some critical updates are needed!</strong>
            </p>
            <p onClick={() => window.location.reload()}>Click here to review them.</p>
          </Notification>
        )}
        {certificateWarnings && (
          <Notification type={"warning"} showsCloseAction>
            <p>
              <strong>Some certificates are unreachable</strong>
            </p>
            <p onClick={() => window.location.reload()}>Click here to review them.</p>
          </Notification>
        )}
        <CertificatesListing showAllCertificates={true} clientId={"-1"} />
      </div>
    </section>
  );
};

export default CertificatesIndexPage;
