import * as React from "react";

import { ReactComponent as IconTrash } from "assets/svg/trash.svg";
import { ReactComponent as IconClose } from "assets/svg/close.svg";
import { ReactComponent as IconAlertSuccess } from "assets/svg/alert-success.svg";

import api from "api";
import {
  ClientObject,
  ClientFormSchema,
  ClientFormObject,
} from "api/endpoints/clients";
import { useEffect, useState } from "react";
import useRequest from "api/use-request";
import { Form, FormNotification } from "components/form/form";
import { FormFieldVariant } from "components/form/common";
import InputField, { InputFieldType } from "components/form/input";
import { CheckboxField } from "components/form/checkbox";
import { SubmitButton } from "components/form/submit-button";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";
import { debug } from "utils/debug";
import DeleteConfirmationAlert, {
  DeleteConfirmationAlertType,
} from "components/form/delete-confirmation";
import { Multiselect } from "components/controls/multiselect-control";
import { Field } from "formik";
import classNames from "classnames";
import { store } from "store";
import { SendResetLinkRequest } from "api/endpoints/password";
import { EditModalSkeleton } from "./skeletons/edit-modal-skeleton";
import formatDate from "utils/date";

export interface IClientDetailsProps {
  isNew: boolean;
  nextLocation: string;
  onUpdate: () => void;
}

function newClientObj(): ClientFormObject {
  return _.clone({
    id: "",
    name: "",
    url: "",
    project_id: ""
  });
}

export function ClientDetails({ isNew, nextLocation, onUpdate }: IClientDetailsProps) {
  let [initialValue, setInitialValue] = useState<ClientFormObject>();
  let [isUploading, setIsUploading] = useState(false);
  let [isDeleting, setIsDeleting] = useState(false);
  let [shouldOpenBrowseDialog, setShouldOpenBrowseDialog] = useState(false);
  let [uploadedFilePath, setUploadedFilePath] = useState("");
  let [uploadingFileName, setUploadingFileName] = useState("");
  let [editedClient, setEditedClient] = useState({} as ClientObject);
  let [passwordResetLinkSent, setPasswordResetLinkSent] = useState(false);
  let [date, setDate] = useState(new Date());

  let validationSchema = ClientFormSchema.omit({
    id: true,
    project_id: true,
  }).nonstrict();

  let history = useHistory();
  let { clientId } = useParams() as {
    clientId: string | undefined;
  };

  const submitHandler = (values: any, formikHelpers: any) => {
    const payload: ClientFormObject = {
      id: clientId ? clientId : "",
      name: values.name,
      url: values.url,
      project_id: values.project_id,
    };

    let update: Promise<ClientObject>;
    if (isNew) {
      update = api.clients.newClient(payload).fetch();
    } else {
      update = api.clients.updateClient(payload, clientId ?? "").fetch();
    }

    return update.then((response) => {
      store.notifications.presentNotification("Client changes were saved.");
      onUpdate();

      if (nextLocation !== "") {
        nextLocation = nextLocation.replace(":clientId", response.id);
        history.replace(nextLocation);
      } else {
        history.goBack();
      }
    });
  };

  const loadClient = async (clientId: string) => {
    let client = await api.clients.getClient({ id: clientId }).fetch();
    if (client) {
      setEditedClient(client);
      setInitialValue(client);
    }
  };

  const initForm = () => {
    if (!clientId) {
      setInitialValue(newClientObj());
    } else {
      loadClient(clientId);
    }
  };

  useEffect(initForm, []);

  useEffect(() => {
    document.body.classList.add("has-modal-open");
    return () => {
      document.body.classList.remove("has-modal-open");
    };
  });

  function deleteEditedClient() {
    if (clientId) {
      let update = api.clients.deleteClient({ id: clientId }).fetch();
      onUpdate();
      update
        .then((response) => {
          setIsDeleting(false);
          history.push(nextLocation);
        })
        .catch((error) => {
          setIsDeleting(false);
          debug(error);
        });
    }
  }

  function closeModal() {
    history.goBack();
  }

  return (
    <>
      {initialValue ? (
        <div className="c-modal__root">
          <div className="c-modal__wrapper c-modal__wrapper--md">
            <div className="c-modal c-modal--overflow">
              <div className="c-modal__header">
                <div className="c-block c-block--spacing-t-extra-small">
                  <div className="o-container-fluid">
                    <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                      <div className="o-col">
                        <div
                          onClick={() => {
                            closeModal();
                          }}
                          className="c-link-cta-basic"
                        >
                          <span>Close</span>
                          <IconClose className="o-svg-icon" />
                        </div>
                      </div>
                      <div className="o-col u-flex-grow">
                        <p className="c-modal__headline">{isNew ? "Add" : "Edit"} Client</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="c-modal__main">
                <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
                  <div className="o-container-fluid">
                    <Form
                      validationSchema={validationSchema}
                      initialValues={initialValue}
                      onSubmit={submitHandler}
                      enableReinitialize={true}
                    >
                      {({ values, errors, setFieldValue }) => (
                        <div className="o-row">
                          <div className="o-col-6@sm o-col-7@md o-col-7@lg">
                            <FormNotification />
                            <div className="o-row">
                              <div className="o-col-6@lg o-col-10@xl">
                                <div className="u-mb-spacer-base-large">
                                  <h6>Client Info</h6>
                                  <div className="o-row o-row--small-gutters">
                                    <div className="o-col-12">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`name`}
                                        placeholder="Name"
                                        label="Name"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                    <div className="o-col-12">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`url`}
                                        placeholder="Website Url"
                                        label="Website Url"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                    <div className="o-col-12">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`project_id`}
                                        placeholder="Project Id"
                                        label="Project Id (optional)"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="o-col-6@sm o-col-5@md o-col-5@lg">
                            <div className="c-card c-card--bg-light">
                              <div className="c-card__body">
                                <div className="c-card__header">
                                  <h6>{isNew ? "Create" : "Update"}</h6>
                                  {!isNew && (
                                    <div className="c-card__desc">
                                      {editedClient && editedClient.updated_at ? (
                                        <p>
                                          Last update: {" "}
                                          {formatDate(editedClient.updated_at)}.
                                        </p>
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="o-row o-row--fluid c-button-group">
                                  <div className="o-col">
                                    <SubmitButton disabled={isUploading}>
                                      <span>{isNew ? "Create" : "Update"} Client</span>
                                    </SubmitButton>
                                  </div>
                                  {!isNew && (
                                    <div className="o-col c-button-group__inline">
                                      <div
                                        className="c-link-cta-basic"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          setIsDeleting(true);
                                        }}
                                      >
                                        <IconTrash className="o-svg-icon" />
                                        <span>Delete client</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <DeleteConfirmationAlert
                                onDelete={deleteEditedClient}
                                onCancel={() => {
                                  setIsDeleting(false);
                                }}
                                resource_label={editedClient.name}
                                show={isDeleting}
                                type={DeleteConfirmationAlertType.Card}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EditModalSkeleton />
      )}
    </>
  );
}
