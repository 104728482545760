import * as React from "react";
import useClickAway from "hooks/use-click-away";
import { Link } from "react-router-dom";

// Hooks
import { useContext, useRef, useState } from "react";
import { SiteObject } from "api/endpoints/sites";

interface ITableSitesCardProps {
  site: SiteObject;
}

const TableSitesCard: React.FunctionComponent<ITableSitesCardProps> = ({ site }) => {
  return (
    <>
      <div className="c-table-row__content">
        <p className="c-table-row__title " style={{ width: "20%" }}>{site.name}</p>
        <p className="c-table-row__title " style={{ width: "20%" }}>{site.domain}</p>
        <p className="c-table-row__title " style={{ width: "20%" }}>{site.project_id ? site.project_id : "Not Defined"}</p>
        <p className="c-table-row__title " style={{ width: "40%" }}>{site.api_key}</p>
      </div>
    </>
  );
};

export default TableSitesCard;
