import HttpClient from "api/http-client";
import * as z from "zod";
import { GetListingRequestSchema, LinksSchema, MetaSchema } from "api/endpoints/common";

const dateRegex =
  /^(?<fullyear>\d{4})-(?<month>0[1-9]|1[0-2])-(?<mday>0[1-9]|[12][0-9]|3[01])T(?<hour>[01][0-9]|2[0-3]):(?<minute>[0-5][0-9]):(?<second>[0-5][0-9]|60)(?<secfrac>\.[0-9]+)?(Z|(\+|-)(?<offset_hour>[01][0-9]|2[0-3]):(?<offset_minute>[0-5][0-9]))$/i;

export const CertificateObjectSchema = z.object({
  id: z.string(),
  client_id: z.string().nonempty(),
  site_id: z.string(),
  hostname: z.string().nonempty(),
  active: z.boolean(),
  valid: z.boolean(),
  issuer: z.string().nonempty(),
  signature: z.string().nonempty(),
  expiration_at: z.string(),
  validation_at: z.string(),
  synced_at: z.string().optional(),
  days_to_expiration: z.number(),
  is_expired: z.boolean(),
  created_at: z.string().regex(dateRegex).optional(),
  updated_at: z.string().regex(dateRegex).optional(),
});

export const CertificateFormSchema = z.object({
  id: z.string(),
  client_id: z.string().nonempty(),
  site_id: z.string(),
  hostname: z.string().nonempty(),
  active: z.boolean(),
  valid: z.boolean(),
  issuer: z.string(),
  signature: z.string(),
  expiration_at: z.string(),
  validation_at: z.string()
});

export const CreateCertificateResponseSchema = CertificateObjectSchema;

export const GetCertificatesResponseSchema = z.object({
  data: z.array(CertificateObjectSchema),
  links: LinksSchema,
  meta: MetaSchema,
});

export const GetCertificatesListSchema = z.array(CertificateObjectSchema);

export const GetCertificatesRequestSchema = GetListingRequestSchema;

export const CertificateObjectIdSchema = z.object({
  id: z.string(),
});

export const CertificateUserObjectIdsSchema = z.object({
  certificate_id: z.string(),
  user_id: z.string(),
});

export type CertificateObject = z.infer<typeof CertificateObjectSchema>;
export type CertificateFormObject = z.infer<typeof CertificateFormSchema>;
export type GetCertificatesRequest = z.infer<typeof GetCertificatesRequestSchema>;
export type GetCertificatesResponse = z.infer<typeof GetCertificatesResponseSchema>;
export type CertificateObjectId = z.infer<typeof CertificateObjectIdSchema>;
export type CertificateUserObjectIds = z.infer<typeof CertificateUserObjectIdsSchema>;

export function getCertificates(data: GetCertificatesRequest) {
  return new HttpClient().request<GetCertificatesResponse>({
    method: "get",
    url: "/certificates",
    validator: GetCertificatesResponseSchema,
    params: data,
  });
}

export function getCertificatesByClientId(clientId: string) {
  return new HttpClient().request<CertificateObject[]>({
    method: "get",
    url: "/certificates/client/" + clientId,
    validator: GetCertificatesListSchema,
  });
}

export function newCertificate(data: Omit<CertificateFormObject, "id">) {
  return new HttpClient().request<CertificateObject>({
    method: "post",
    url: "/certificates",
    validator: CertificateObjectSchema,
    data,
  });
}

export function getCertificate(data: CertificateObjectId) {
  return new HttpClient().request<CertificateObject>({
    method: "get",
    url: `/certificates/${data.id}`,
    validator: CertificateObjectSchema,
  });
}

export function deleteCertificate(data: CertificateObjectId) {
  return new HttpClient().request({
    method: "delete",
    url: `/certificates/${data.id}`,
    data,
  });
}

export function updateCertificate(data: CertificateFormObject, certificate_id: string) {
  return new HttpClient().request<CertificateObject>({
    method: "put",
    url: `/certificates/${certificate_id}`,
    data,
  });
}

export function syncCertificate(data: CertificateUserObjectIds) {
  return new HttpClient().request<CertificateObject>({
    method: "post",
    url: `/certificates/${data.certificate_id}/sync`,
    data,
  });
}

export function mockCertificateIssue() {
  return new HttpClient().request<CertificateObject>({
    method: "post",
    url: `/certificates/mockIssue`,
  });
}
