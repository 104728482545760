import * as React from "react";

import classNames from "classnames";

// Hooks
import { useContext, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as quartzite from "quartzite";

interface ITableCardProps {
  itemId: string;
  index: number
}

const TableCard: React.FunctionComponent<ITableCardProps> = ({
  itemId,
  index,
  children,
}) => {
  const classNameSelected = "c-card__link-hidden";

  return (
    <div className="c-table-item">
      <div className="c-table-row">
        {children}
      </div>
      <div className="c-table__hidden c-table-row">
      </div>
    </div>
  );
};

export default TableCard;
