import * as React from "react";

import { useLocation, useHistory } from "react-router-dom";

// Icons
import { ReactComponent as IconAddItem } from "assets/svg/add-item.svg";

interface IAddNewBlockProps {
  title: string;
  subtitle: string;
}

const AddNewBlock: React.FunctionComponent<IAddNewBlockProps> = ({ title, subtitle }) => {
  let history = useHistory();
  let location = useLocation();
  let newItemLink = location.pathname + "/new";

  return (
    <div className="u-mb-spacer-base-large">
      <div className="c-add c-add--link u-flex-nowrap@md">
        <div className="c-add__icon">
          <IconAddItem className="o-svg-icon" />
        </div>
        <div className="c-add__body">
          <div className="c-add__title">
            <div
              onClick={() => {
                history.push(newItemLink);
              }}
              className="c-link-cta c-add__link"
            >
              <span>{title}</span>
            </div>
          </div>
          <div className="c-add__desc">
            <p>{subtitle}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewBlock;
