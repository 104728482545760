import { Field as FormikField, FieldConfig, useFormikContext } from 'formik';
import * as React from 'react';

import ErrorMessage from './error-message';
import classNames from 'classnames';
import { FormFieldVariant, FormFieldSizing } from 'components/form/common';

export enum InputFieldType {
  'text' = 'text',
  'email' = 'email',
  'password' = 'password',
  'timestamp' = 'timestamp'
}

type InputFieldProps<T> = JSX.IntrinsicElements['input'] &
  Omit<FieldConfig<T>, 'component' | 'as' | 'render' | 'children' | 'type'> & {
    name: string;
  } & {
    type: InputFieldType;
    variant?: FormFieldVariant;
    sizing?: FormFieldSizing;
    icon?: React.ReactNode | [React.ReactNode, React.ReactNode];
    label?: string;
    validateOnInit?: boolean;
    placeholder: string;
    textarea?: boolean;
    help?: string;
    elementClassName?: string;
    autocomplete?: boolean;
  }

const validateTimestamp = (value: string) => {
  let errorMessage;
  if (!/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/.test(value)) {
    errorMessage = 'Invalid format, expected YYYY-MM-DD hh:mm:ss';
  }
  return errorMessage;
};

const validateNotEmpty = (value: string) => {
  let errorMessage;
  if (value == "") {
    errorMessage = 'Required';
  }
  return errorMessage;
};

export const InputField = <T extends any>({
  label,
  icon,
  name,
  validateOnInit,
  type = InputFieldType.text,
  variant,
  required = false,
  autocomplete = false,
  disabled,
  elementClassName,
  textarea,
  sizing,
  help,
  ...formikFieldProps
}: InputFieldProps<T>): React.ReactElement => {
  const { isSubmitting, getFieldMeta } = useFormikContext<any>();
  const fieldMeta = getFieldMeta(name);
  const errorMsg = fieldMeta.error;
  const hasError = typeof errorMsg !== 'undefined' && fieldMeta.touched;

  return (
    <div className={classNames([
      'c-form-element',
      {
        ['c-form-element' + sizing]: sizing,
        ['c-form-element' + variant]: variant,
        'c-form-element--error': hasError
      }
    ])}>
      {label && (
        <label htmlFor={name} className={classNames('c-form-label')}>{label}</label>
      )}
      <div className="c-form-element__field">

        <FormikField
          name={name}
          type={type}
          validate={type == "timestamp" ? validateTimestamp : (required ? validateNotEmpty : undefined)}
          as={textarea ? 'textarea' : undefined}
          disabled={isSubmitting ? true : disabled}
          autoComplete={autocomplete ? "on" : "off"}
          {...formikFieldProps}
        />
      </div>
      <ErrorMessage name={name} />
      {help && (
        <p className="c-note">{help}</p>
      )}
    </div>
  );
};

export default InputField;
