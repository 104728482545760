import * as React from "react";

import { ClientObject } from "api/endpoints/clients";
import { Link, useLocation } from "react-router-dom";
import * as quartzite from "quartzite";
import formatDate from "utils/date";

interface IClientCardContentProps {
  client: ClientObject;
}

const ClientCardContent: React.FunctionComponent<IClientCardContentProps> = ({ client }) => {
  let location = useLocation();
  let editClientLink = location.pathname + `/${client.id}/edit`;

  return (
    <div className="c-card__content">
      <p className="c-card__title">
        <Link to={editClientLink}>{client.name}</Link>
      </p>
      <div className="c-card__info">
          <p>Website Url: {client.url}</p>
      </div>
    </div>
  );
};

export default ClientCardContent;
