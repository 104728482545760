import * as React from "react";

import { Switch, Route } from "react-router-dom";
import { useStore } from "store";
import { SiteDetails } from "components/cards/site-edit";

export interface ISiteEditModalProps {}

const SiteEditModal: React.FunctionComponent<ISiteEditModalProps> = () => {
  const { refreshs } = useStore();

  function onUpdate() {
    refreshs.setSitesRefresh(true);
  }

  return (
    <Switch>
      <Route path={`/sites/:siteId/edit`}>
        <SiteDetails isNew={false} nextLocation={`/sites`} onUpdate={onUpdate} />
      </Route>
      <Route path={`/sites/new`}>
        <SiteDetails isNew={true} nextLocation={`/sites`} onUpdate={onUpdate} />
      </Route>
      <Route path={`/sites/:siteId/view/edit`}>
        <SiteDetails isNew={false} nextLocation={`/sites/:siteId/view`} onUpdate={onUpdate} />
      </Route>
    </Switch>
  );
};

export default SiteEditModal;
