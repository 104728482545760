import HttpClient from 'api/http-client';
import Cookies from 'js-cookie';
import { types } from 'mobx-state-tree';
import config from 'config';
import { isString } from 'lodash';

const cookieOptions:Cookies.CookieAttributes = { secure: true, sameSite: "None" };

export const Tokens = types
  .model({
    accessToken: types.maybeNull(types.string),
    refreshToken: types.maybeNull(types.string),
  })
  .views((self) => ({
    get hasToken() {
      return (self.accessToken)? true : false;
    }
  }))
  .actions((self) => ({
    setAccessToken: function(token: string | null) {
      self.accessToken = token;
      const client = new HttpClient();
      client.setBearer(token);
      if (isString(token)) {
        Cookies.set(config.COOKIE_ACCESS_TOKEN_KEY, token, cookieOptions);
      } else {
        Cookies.remove(config.COOKIE_ACCESS_TOKEN_KEY);
      }
    },
    setRefreshToken: function(token: string | null) {
      self.refreshToken = token;
      if (isString(token)) {
        Cookies.set(config.COOKIE_REFRESH_TOKEN_KEY, token, cookieOptions);
      } else {
        Cookies.remove(config.COOKIE_REFRESH_TOKEN_KEY);
      }
    },
  }))
  .actions((self) => ({
    restoreTokens: function() {
      const accessToken = Cookies.get(config.COOKIE_ACCESS_TOKEN_KEY);
      const refreshToken = Cookies.get(config.COOKIE_REFRESH_TOKEN_KEY);
      if (accessToken && refreshToken) {
        self.setAccessToken(accessToken);
        self.setRefreshToken(refreshToken);
      }
    },
  }))

export default Tokens;
