import HttpClient from 'api/http-client';
import * as z from 'zod';

export const GetStatusResponseSchema = z.object({
  db: z.boolean(),
  pending: z.number(),
  failed: z.number(),
  emoji: z.string(),
  version: z.any(),
  build: z.any(),
});

export const GetImageResponseSchema = z.object({
  filename: z.any()
});

export type GetStatusResponse = z.infer<typeof GetStatusResponseSchema>
export type GetImageResponse = z.infer<typeof GetImageResponseSchema>

export function getStatus() {
  return new HttpClient().request<GetStatusResponse>({
    method: 'get',
    url: '/status',
    validator: GetStatusResponseSchema,
  });
}

export function uploadImage(data: any) {
  let formData = new FormData();
  formData.append('image', data.file);
  formData.append('directory', data.directory);

  var is_multipart = true;

  return new HttpClient().request<GetImageResponse>({
    method: 'post',
    url: '/upload-image',
    validator: GetImageResponseSchema,
    data: formData
  }, is_multipart);
}
